import axios from '../api';
import { AxiosResponse } from 'axios';
import { RitList, RitDetail, RitStatus, RitDocument, SuccessResponse } from "../../helpers/interfaces/admin";
import { IDocumento } from '../../helpers/interfaces/documento';

const API_URL = process.env.REACT_APP_API_URL;

class RitAmministratoreService {

    getRitList(): Promise<RitList[]> {
    return axios.get(API_URL + 'datore/rit')
        .then((response: AxiosResponse<RitList[]>) => { return response.data; })
        .catch((error) => {return Promise.reject(error); });
    }

    getOneRit(id: number): Promise<RitDetail> {
        return axios.get(API_URL + 'datore/rit/' + id)
            .then((response: AxiosResponse<RitDetail>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    getStatusList(): Promise<RitStatus[]> {
    return axios.get(API_URL + 'datore/rit/status')
        .then((response: AxiosResponse<RitStatus[]>) => { return response.data; })
        .catch((error) => {return Promise.reject(error); });
    }

    putStatus(id: number, body: RitDetail): Promise<any> {
        const payload = {
            stato: body.stato_id,
            note: body.note
        }
        return axios.put<any>(API_URL + 'datore/rit/' + id + '/gestione', payload)
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    putRitData(id: number, body: RitDocument): Promise<SuccessResponse> {
        return axios.put<any>(API_URL + 'datore/rit/' + id + '/invia', body)
            .then((response: AxiosResponse<SuccessResponse>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
      }

    deleteRit(id: number): Promise<any> {
        return axios.delete(API_URL + 'datore/rit/' + id)
            .then((response: AxiosResponse<any>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    downloadPdfRit(id: number): Promise<IDocumento> {
        return axios.get(API_URL + 'datore/rit/pdf/' + id)
            .then((response: AxiosResponse<IDocumento>) => { return response.data; })
            .catch((error) => {return Promise.reject(error); });
    }
}

export default new RitAmministratoreService();
