import React, { Component } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Swal from 'sweetalert2';
import DatePickerForm from '../../../common/DatePickerForm';
import { IStrumentoForm, strumentiFormFields, mapListsData } from '../../../config/formFields/magazzino/magazzino-strumenti';
import { IMagazzinoContrattoNoleggio } from '../../../helpers/interfaces/magazzino/magazzino-contratti-noleggi';
import { IMagazzinoStrumento, IMagazzinoStrumentoBody } from '../../../helpers/interfaces/magazzino/magazzino-strumenti';
import magazzinoContrattiNoleggiService from '../../../services/api/magazzino/magazzino-contratti-noleggi.service';
import magazzinoStrumentiService from '../../../services/api/magazzino/magazzino-strumenti.service';
import tipologiaStrumentiService from '../../../services/api/magazzino/magazzino-tipologia-strumenti.service';
import categoriaStrumentiService from '../../../services/api/magazzino/magazzino-categoria-strumenti.service';
import statoStrumentiService from '../../../services/api/magazzino/magazzino-stato-strumenti.service';
import EventBus from '../../../common/EventBus';
import { iFormField, iFormFieldValue } from '../../../helpers/interfaces/generic';
import documentiService from '../../../services/api/documenti.service';
import { base64toBlob } from '../../../common/Base64';
import { IMagazzinoStatoStrumento } from '../../../helpers/interfaces/magazzino/magazzino-stato-strumenti';
import { IMagazzinoTipologiaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-strumenti';
import { IMagazzinoCategoriaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-categoria-strumenti';
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';

type Props = {
    history: {
        push(url: string): void;
    }
    match: {
        params: {
            id: string
        };
    }
};

type State = {
    strumento: IMagazzinoStrumento,
    stati: IMagazzinoStatoStrumento[],
    tipologie: IMagazzinoTipologiaStrumento[],
    categorie: IMagazzinoCategoriaStrumento[],
    contratti: IMagazzinoContrattoNoleggio[],
    formFields: iFormField[],
    loading: boolean,
    disabledForm: boolean,
    formInitialValues: IStrumentoForm,
    attachmentsToUpload: File[]
};

export default class MagazzinoDettaglioStrumento extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            strumento: {} as IMagazzinoStrumento,
            stati: [],
            tipologie: [],
            categorie: [],
            contratti: [],
            disabledForm: true,
            formFields: [],
            loading: false,
            formInitialValues: {} as IStrumentoForm,
            attachmentsToUpload: []
        }

        this.setValidations = this.setValidations.bind(this)
        this.handleUpdateStrumento = this.handleUpdateStrumento.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this)
    }

    // method: initializing the form values
    initFormValues(): IStrumentoForm {
        let stato = this.state.stati.find(stato => stato.id === this.state.strumento.stato_id);
        let tipologia = this.state.tipologie.find(tipologia => tipologia.id === this.state.strumento.tipologia_id);
        let contratto = this.state.contratti.find(contratto => contratto.id === this.state.strumento.contrattinoleggio_id);

        return {
            stato_nome: stato ? stato.nome : "",
            tipologia_nome: tipologia ? tipologia.nome : "",
            categoria_nome: this.state.strumento.categoria_nome ? this.state.strumento.categoria_nome : "",
            categoria_id: this.state.strumento.categoria_id ? this.state.strumento.categoria_id : 0,
            internalid: this.state.strumento.internalid ? this.state.strumento.internalid : "",
            code: this.state.strumento.code ? this.state.strumento.nome : "",
            marca_modello: this.state.strumento.marca_modello ? this.state.strumento.marca_modello : "",
            serial_number: this.state.strumento.serial_number ? this.state.strumento.serial_number : "",
            builder: this.state.strumento.builder ? this.state.strumento.builder : "",
            misuramentrange: this.state.strumento.misuramentrange ? this.state.strumento.misuramentrange : "",
            calibrationdate: this.state.strumento.calibrationdate ? this.state.strumento.calibrationdate : "",
            frequency: this.state.strumento.frequency ? this.state.strumento.frequency : "",
            expiration: this.state.strumento.expiration ? this.state.strumento.expiration : "",
            location: this.state.strumento.location ? this.state.strumento.location : "",
            purchase: this.state.strumento.purchase ? this.state.strumento.purchase : "",
            warranty: this.state.strumento.warranty ? this.state.strumento.warranty : "",
            verify: this.state.strumento.verify ? this.state.strumento.verify : "",
            acceptability: this.state.strumento.acceptability ? this.state.strumento.acceptability : "",
            disponibile: this.state.strumento.disponibile ? this.state.strumento.disponibile : false,
            noleggio: this.state.strumento.noleggio ? "Sì" : "No",
            contrattonoleggio: contratto ? contratto.nome + ' - ' + contratto.codice : "",
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento strumento in corso...' });

        const strumentoPromise = magazzinoStrumentiService.getOne(this.props.match.params.id);
        const statiPromise = statoStrumentiService.getAll();
        const tipologiePromise = tipologiaStrumentiService.getAll();
        const categoriePromise = categoriaStrumentiService.getAll();
        const contrattiPromise = magazzinoContrattiNoleggiService.getAll();

        // calling the needed data
        await Promise.all([strumentoPromise, statiPromise, tipologiePromise, categoriePromise, contrattiPromise])
            .then(
                values => {
                    this.setState(
                        {
                            strumento: values[0],
                            stati: values[1],
                            tipologie: values[2],
                            categorie: values[3],
                            contratti: values[4]
                        },
                        () => {
                            const stati = mapListsData(this.state.stati);
                            const tipologie = mapListsData(this.state.tipologie);
                            const categorie = mapListsData(this.state.categorie);
                            const contratti = mapListsData(this.state.contratti);

                            this.setState({
                                formFields: strumentiFormFields(stati, tipologie, categorie, contratti),
                                formInitialValues: this.initFormValues(),
                                attachmentsToUpload: []
                            }, () => EventBus.dispatch("hideLoader"))
                        }
                    )
                },
                error => {
                    EventBus.dispatch("hideLoader");
                }
            );
    }

    // method: handling edit device actions
    async handleUpdateStrumento(values: IStrumentoForm) {

        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        const stato = this.state.stati.find(stato => stato.nome === values.stato_nome);
        const tipologia = this.state.tipologie.find(tipologia => tipologia.nome === values.tipologia_nome);
        const categoria = this.state.categorie.find(categoria => categoria.nome === values.categoria_nome);
        const contratto = this.state.contratti.find(contratto => contratto.nome + " - " + contratto.codice === values.contrattonoleggio);

        let formData = new FormData();
        const { attachmentsToUpload } = this.state;
        if (attachmentsToUpload) {
            let files = Array.from(attachmentsToUpload)
            files.forEach((file, i) => formData.append("attachment_" + i, file))
        }

        let strumentoBody: IMagazzinoStrumentoBody = {
            stato_id: stato ? stato.id : undefined,
            tipologia_id: tipologia ? tipologia.id : 0,
            categoria_id: categoria ? categoria.id : 0,
            code: values.code,
            internalid: values.internalid,
            marca_modello: values.marca_modello,
            serial_number: values.serial_number,
            builder: values.builder,
            misuramentrange: values.misuramentrange,
            calibrationdate: values.calibrationdate,
            frequency: values.frequency,
            expiration: values.expiration,
            location: values.location,
            purchase: values.purchase,
            warranty: values.warranty,
            verify: values.verify,
            acceptability: values.acceptability,
            disponibile: values.disponibile,
            noleggio: values.noleggio === "Sì" ? 1 : (values.noleggio === "No" ? 0 : null),
            contrattinoleggio_id: contratto ? contratto.id : undefined,
        }

        for (const key of Object.keys(strumentoBody) as (keyof IMagazzinoStrumentoBody)[]) {
            formData.append(key, strumentoBody[key]?.toString() ?? '');
        }

        // handle edit call
        await magazzinoStrumentiService.edit(this.props.match.params.id, formData).then(
            response => {
                EventBus.dispatch("hideLoader");
                Swal.fire(
                    'Operazione eseguita.',
                    '',
                    'success'
                ).then(
                    () => this.setState(
                        {
                            loading: false
                        },
                        () => this.props.history.push("/magazzino/strumenti")
                    )
                );
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    // method: saving files in the state
    handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { attachmentsToUpload } = this.state
        let files = event.currentTarget.files ? Array.from(event.currentTarget.files) : []
        if (attachmentsToUpload.length === 0) {
            this.setState({ attachmentsToUpload: files }, () => { event.target.value = "" })
        } else {
            let concatArray = files.concat(attachmentsToUpload)
            // removing duplicates after merging new and old files
            let attachments = concatArray.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.name === value.name &&
                    t.size === value.size &&
                    t.type === value.type &&
                    t.lastModified === value.lastModified
                ))
            )
            this.setState({ attachmentsToUpload: attachments }, () => { event.target.value = "" })
        }
    }

    async downloadAttachment(id: number) {
        EventBus.dispatch("showLoader", { text: 'Download in corso...' });

        this.setState({
            loading: true
        })

        await documentiService.downloadDipendente(id).then(
            documento => {
                EventBus.dispatch("hideLoader");
                this.setState({
                    loading: false
                })

                let fileBlob = base64toBlob(documento.body, documento.mimetype)
                let url = window.URL.createObjectURL(fileBlob);
                let a = document.createElement('a');
                a.href = url;
                a.download = documento.filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            },
            () => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    async deleteAttachment(id: number) {
        EventBus.dispatch("showLoader", { text: 'Eliminazione in corso...' });

        this.setState({
            loading: true
        });

        await documentiService.delete(id).then(
            () => this.setState(
                (prevState) => {
                    return {
                        ...prevState,
                        strumento: {
                            ...prevState.strumento,
                            attachments: prevState.strumento.attachments.filter(a => a.id !== id)
                        }
                    }
                },
                () => {
                    EventBus.dispatch("hideLoader");
                    Swal.fire(
                        'Operazione eseguita.',
                        '',
                        'success'
                    );
                    this.setState({
                        loading: false
                    });
                }
            ),
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: rendering form filed based on type
    renderFormFields(field: iFormField, setFieldValue: (name: string, value: any, shouldValidate?: boolean | undefined) => void): JSX.Element {
        switch (field.type) {
            case 'select':
                return (
                    <Field as={field.type} name={field.name} className={field.class} disabled={this.state.disabledForm}>
                        <option key={''} value={''}>---</option>
                        {field.values?.map((item: iFormFieldValue) => {
                            return <option key={item.key} value={item.value}>{item.value}</option>
                        })}
                    </Field>
                );
            case 'date':
                return (<DatePickerForm name={field.name} className={field.class} readValue={String(field.value)} disabled={this.state.disabledForm} onChange={(state) => setFieldValue(field.name, state.toSend)} />);
            case 'file':
                const { attachmentsToUpload, strumento } = this.state
                return (
                    <React.Fragment>
                        <Field id={field.name} name={field.name} multiple={true} className={field.class} type={field.type} onChange={this.handleFileChange} disabled={this.state.disabledForm} />
                        <div className='d-flex'>
                            <div className='col-6 mt-3'>
                                <h4>File caricati</h4>
                                {
                                    strumento.attachments.length !== 0 ? strumento.attachments.map((file: any, index: number) => (
                                        <div className='d-flex align-items-center mb-1' key={index + '_' + Date.now()}>
                                            <button style={buttonsStyle} className='btn btn-outline-danger rounded-circle me-1' type='button' disabled={this.state.disabledForm} onClick={() => this.deleteAttachment(file.id)}>
                                                <i style={iconsStyle} className="fa fa-trash-o" aria-hidden="true"></i>
                                            </button>
                                            <button style={buttonsStyle} className='btn btn-outline-primary rounded-circle me-2' type='button' onClick={() => this.downloadAttachment(file.id)} >
                                                <i style={iconsStyle} className="fa fa-download" aria-hidden="true"></i>
                                            </button>
                                            <span>
                                                {file.path}
                                            </span>
                                        </div>
                                    )) : <p className='mt-3'> Nessun file da visualizzare </p>
                                }
                            </div>

                            {
                                attachmentsToUpload.length !== 0 &&
                                <div className='col-6 mt-3'>
                                    <h4>File da caricare</h4>
                                    {attachmentsToUpload.map((file, index) => (
                                        <div className='d-flex align-items-center mb-1'>
                                            <button style={buttonsStyle} className='btn btn-outline-danger rounded-circle me-2' type='button' disabled={this.state.disabledForm} onClick={() => {
                                                let files = attachmentsToUpload
                                                files.splice(files.indexOf(file), 1)
                                                this.setState({ attachmentsToUpload: files })
                                            }}>
                                                <i style={iconsStyle} className="fa fa-trash-o" aria-hidden="true"></i>
                                            </button>
                                            <span key={index + '_' + Date.now()}>
                                                {file.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>

                    </React.Fragment>
                );
            default:
                return (<Field name={field.name} type={field.type} className={field.class} disabled={this.state.disabledForm} />);
        }
    }

    render() {
        const { loading, disabledForm, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof IStrumentoForm])

        return <div className="container py-3">
            {
                formFields.length > 0 &&
                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleUpdateStrumento}
                    >
                        {({ setFieldValue }) => (
                            <Form className="card">
                                <div className="card-body">
                                    <div className='col-12 pb-2 d-flex justify-content-between align-items-center'>
                                        <h3>Dettaglio strumento</h3>
                                        <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
                                            {
                                                disabledForm ?
                                                    <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                                                    <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                                            }
                                        </button>
                                    </div>

                                    {formFields.map(
                                        (field: iFormField, key) => {
                                            return <div className="mb-3" key={key}>
                                                <div className='col-12'>
                                                    <label className="form-label">{field.label}</label>
                                                    {
                                                        this.renderFormFields(field, setFieldValue)
                                                    }
                                                    <ErrorMessage
                                                        name={field.name}
                                                        component="div"
                                                        className="alert alert-danger"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    )}

                                    <div className="col-12 d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                                            {
                                                loading && <span className="spinner-border spinner-border-sm mr-1"></span>
                                            }
                                            <span>Salva</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </React.Fragment>
            }
        </div>
    }
}