import { AxiosResponse } from 'axios';
import { GroupAll, GroupAddRequest, GroupAddResponse, GroupDetails, groupAddPersonRequest, groupDeletePersonRequest } from '../../../helpers/interfaces/group';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class GroupsService {
  async getAll(): Promise<Array<GroupAll>> {
    return await axios.get(API_URL + 'database/groups')
    .then((response: AxiosResponse<Array<GroupAll>>) => { 
        return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error) => { return Promise.reject(); });
  }

  async getAllScadenziario(): Promise<Array<GroupAll>> {
    return await axios.get(API_URL + 'groups')
    .then((response: AxiosResponse<Array<GroupAll>>) => { 
        return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error) => { return Promise.reject(); });
  }

  async get(id: number) {
    return await axios.get(API_URL + 'database/groups/' + id)
    .then((response: AxiosResponse<GroupDetails>) => { return response.data; })
    .catch((error) => { return Promise.reject(error); });
  }

  async add(data: GroupAddRequest) {
    return await axios.post(API_URL + 'database/groups', {
      ...data
    })
    .then((response: AxiosResponse<GroupAddRequest | GroupAddResponse>) => { return response.data; })
    .catch((error) => { return Promise.reject(error); });
  }

  async edit(id:number, data: GroupAddRequest) {
    return await axios.put(API_URL + 'database/groups/' + id, {
      ...data
    })
    .then((response: AxiosResponse<GroupAddRequest | GroupAddResponse>) => { return response.data; })
    .catch((error) => { return Promise.reject(error); });
  }

  async addPerson(data: groupAddPersonRequest) {
    return await axios.post(API_URL + 'database/groups/person', {
      group: data.groupID,
      person: data.personID
    })
    .then((response: AxiosResponse<any>) => { return response.data; })
    .catch((error) => { return Promise.reject(error); });
  }

  async delete(id: number) {
    return await axios.delete(API_URL + 'database/groups/' + id)
    .then(response => { return response.data; })
    .catch((error) => { return Promise.reject(error); });
  }

  async deletePerson(data: groupDeletePersonRequest) {
    return await axios.post(API_URL + 'database/groups/removeperson', data)
    .then(response => { return response.data; })
    .catch((error) => { return Promise.reject(error); });
  }
}

export default new GroupsService();
