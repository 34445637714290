import React, { Component } from "react";
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import ModalForm from '../../../common/ModalForm';
import ClientiService from "../../../services/api/clienti.service";
import CommesseService from "../../../services/api/commesse.service";
import AttivitaService from  "../../../services/api/database/attivita.service";
import { listCommesseColumns, getListCommesseColumnDefs } from "../../../config/tableColumns/commesse";
import { getEditCommessaFormFields, getAddCommessaFormFields } from "../../../config/formFields/commesse";
import Table from '../../../common/TableComponent';
import { iFormField, iFormFieldOption, iFormFieldValue } from "../../../helpers/interfaces/generic";
import personService from "../../../services/api/person.service";
import { getPersonListCommessaFormFields } from "../../../config/formFields/commesse";
import { Customer, Activity, ParentJoborder, JoborderResponse, JoborderObject } from "../../../helpers/interfaces/joborders";

const $ = require('jquery');

type Props = {
    history: any
};

type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    commesse: JoborderResponse,
    formInitialValues: { [key: string]: any },
    currentJobrder: 'active' | 'expired',
    jobOrdersFromClient: iFormFieldValue[],
    activities: Activity[],
    filterActivities: (iFormFieldValue|iFormFieldOption)[],
    isFirstLevel: boolean,
}

export default class Commesse extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;
    jobOrder: any[];
    clienti: any[];
    attivita: any[];

    constructor(props: Props) {
        super(props);

        this.state = {
            formFields: [],
            commesse: {
                active: [],
                expired: []
            },
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            modalTitle: '',
            formInitialValues: {},
            currentJobrder: 'active',
            jobOrdersFromClient: [],
            activities: [],
            filterActivities: [],
            isFirstLevel: false,
        }

        this.innerRefs = [];
        this.apiSubmit = null;
        this.buttons = null;
        this.jobOrder = [];
        this.clienti = [];
        this.attivita = [];
    }

    async getFilterActivities(
        value: any,
        type: 'add' | 'edit'
    ): Promise<{
        data: (iFormFieldValue|iFormFieldOption)[],
        isFirstLevel?: boolean
    }> {
        const activeJobOrders = Array.from(this.state.commesse.active);
        const jobOrder = activeJobOrders.filter(
            (item: JoborderObject) => {
                // eslint-disable-next-line eqeqeq
                return item.id == value
            }
        )[0];

        if (!jobOrder) {
            Swal.fire(
                "Errore",
                "Non è stato possibile recuperare la commessa selezionata",
                "error"
            );

            return {
                data: []
            };
        }

        let activities: Activity[] = [];

        if (jobOrder.rittype.id === 1) {
            activities = this.state.activities.filter(
                (item) => {
                    return item.type === 'O'
                }
            )
        } else if (jobOrder.rittype.id === 2) {
            activities = this.state.activities.filter(
                (item) => {
                    return item.type === 'C'
                }
            )
        }

        const data: (iFormFieldValue|iFormFieldOption)[] = activities.map(
            (activity) => {
                if (type === 'add') {
                    return {
                        value: activity.id,
                        label: `${activity.code} - ${activity.descrizione}`
                    };
                } else {
                    return {
                        key: activity.id,
                        value: `${activity.code} - ${activity.descrizione}`
                    };
                }
            }
        );

        return {
            data,
            isFirstLevel: !jobOrder?.parentJoborder
        }
    }

    async setFormFields(
        type: 'add' | 'edit'
    ) {
        this.setState({
            formFields: type === 'add'
                ? getAddCommessaFormFields(
                    this.clienti,
                    this.callbackAddJoborderFromCustomer.bind(this),
                    this.state.jobOrdersFromClient,
                    this.state.filterActivities,
                    this.selectParentJobOrder.bind(this),
                ) :  getEditCommessaFormFields(
                    this.clienti,
                    this.callbackEditJoborderFromCustomer.bind(this),
                    this.state.jobOrdersFromClient,
                    this.state.filterActivities,
                    this.state.isFirstLevel,
                    this.selectParentJobOrder.bind(this),
                    this.selectActivity.bind(this),
                ),
        });
    }

    async selectParentJobOrder(
        value: any,
        type: 'add' | 'edit'
    ): Promise<number | undefined> {
        const jobOrder: JoborderObject = this.state.commesse.active.filter(
            (jobOrder) => {
                // eslint-disable-next-line eqeqeq
                return jobOrder.id == value
            }
        )[0];

        if (!jobOrder) {
            Swal.fire(
                "Errore",
                "Non è stato possibile recuperare la commessa selezionata",
                "error"
            );

            return;
        }

        const jobOrderRitType = Number(jobOrder.rittype.id);

        await this.getFilterActivities(value, type).then(
            (filterActivities) => {
                this.setState({
                    filterActivities: filterActivities.data,
                    isFirstLevel: !jobOrder?.parentJoborder
                }, async () => {
                    await this.setFormFields(type);
                });
            }
        );

        return jobOrderRitType;
    }

    async selectActivity(
        value: any,
        type: 'add' | 'edit'
    ): Promise<number> {
        const activity = this.state.activities.filter((activity) => {
            return activity.id === value
        })[0];

        const activityRitType = activity.type === "O" ? 1 : 2;

        await this.setFormFields(type);

        return activityRitType;
    }

    async callbackEditJoborderFromCustomer(customerId: number, isFirstLevel: boolean) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const commesse_all: any = await CommesseService.fromCustomer(customerId);
        let commesse: any = Object.keys(commesse_all).map((key: any) => {
            return { key: commesse_all[key].id, value: commesse_all[key].jobordername }
        });

        this.setState({ 
            jobOrdersFromClient: commesse,
            formFields: getEditCommessaFormFields(
                this.clienti,
                this.callbackEditJoborderFromCustomer.bind(this),
                commesse,
                this.state.filterActivities,
                isFirstLevel,
                this.selectParentJobOrder.bind(this),
                this.selectActivity.bind(this),
            )
        });

        EventBus.dispatch("hideLoader");
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", {
            text: 'Caricamento dati in corso... il tempo di attesa potrebbe essere lungo.'
        });

        let commesse: any = await CommesseService.all();

        commesse.active.forEach((item: any) => {
            if (item.childs.length > 0) {
                item.childs.forEach((item: any) => {
                    commesse.active.push(item);
                });
            }
        });

        commesse.expired.forEach((item: any) => {
            if (item.childs.length > 0) {
                item.childs.forEach((item: any) => {
                    commesse.expired.push(item);
                });
            }
        });

        if (commesse) {
            this.setState({ commesse });
        }

        EventBus.dispatch("hideLoader");

        $('#table_commesse.table').on('click', '.assign_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento personale in corso...' });
            const commessaId = $(e.currentTarget).data('id');
            const commessaAttiva = this.state.commesse.active.find((commessa: any) => commessa.id === commessaId);
            const commessaScaduta = this.state.commesse.expired.find((commessa: any) => commessa.id === commessaId);
            const personale = await personService.getAllForSearch();
            if (personale) {
                let assegnatari: any[] = []
                if (commessaAttiva) {
                    assegnatari = Object.values(commessaAttiva.persons).map((person: any) => {
                        return { label: person.name, value: person.id }
                    })
                }

                if (commessaScaduta) {
                    assegnatari = Object.values(commessaScaduta.persons).map((person: any) => {
                        return { label: person.name, value: person.id }
                    })
                }

                const personaleForSelect = personale.data
                    .filter(person => person.status !== 'D' && person.status !== 'S')
                    .map(person => { return { label: person.nominativo, value: person.id } })

                this.setState(
                    {
                        formFields: getPersonListCommessaFormFields(personaleForSelect),
                        formInitialValues: {
                            enddate: commessaAttiva ? commessaAttiva.expired : commessaScaduta?.expired,
                            startdate: commessaAttiva ? commessaAttiva.start : commessaScaduta?.start,
                            joborderid: commessaAttiva ? commessaAttiva.id : commessaScaduta?.id,
                            ids: assegnatari
                        }
                    },
                    () => {
                        this.apiSubmit = CommesseService.personAddList;
                        this.setState({ showModal: true, modalTitle: 'Aggiungi personale a ' + commessaAttiva?.name, modalType: 'add' });
                    }
                );
            }

            EventBus.dispatch("hideLoader");
        });

        $('#table_commesse.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento Commessa in corso...' });

            const idCommessa = $(e.currentTarget).data('id');
            const commessa: any = await CommesseService.get(idCommessa);

            const isFirstLevel = !commessa?.parentJoborder;
            const rateHourly: number = commessa?.rateIsHourly ? 1 : 0;
 
            const _clienti: Customer[] = await ClientiService.getAll();
            const clienti: iFormFieldValue[] = _clienti.map((item: Customer) => {
                return { key: item.id, value: item.ragionesociale }
            });
            
            const _commesse: ParentJoborder[] = await CommesseService.fromCustomer(commessa.customerid);
            const commesse: iFormFieldValue[] = Object.keys(_commesse).map((key: any) => {
                return { key: _commesse[key].id, value: _commesse[key].jobordername }
            });
            const commessaPadre: iFormFieldValue[] = commesse.filter((item: any) => item.value === commessa.parentJoborder );

            const _attivita: Activity[] = await AttivitaService.getAllDatore();
            const attivita: iFormFieldValue[] = _attivita.map((item: any) => {
                return { key: item.id, value: item.code + ' - ' + item.descrizione }
            });

            this.clienti = clienti;
            this.attivita = attivita;

            await this.selectParentJobOrder(idCommessa, 'edit');

            if (commessa) {
                this.setState({
                    activities: _attivita,
                    formFields: getEditCommessaFormFields(
                        clienti,
                        this.callbackEditJoborderFromCustomer(commessa.customerid, isFirstLevel),
                        this.state.jobOrdersFromClient,
                        attivita,
                        isFirstLevel,
                        this.selectParentJobOrder.bind(this),
                        this.selectActivity.bind(this),
                    ),
                    formInitialValues: {
                        ...commessa, id: commessa.joborderid, parentJoborderId: commessaPadre[0]?.key,
                        rateIsHourly: rateHourly, rittype: commessa.rittype.id
                    }
                }, () => {
                    this.apiSubmit = isFirstLevel ? CommesseService.put1stLevel : CommesseService.put2ndLevel;
                    this.setState({ showModal: true, modalTitle: 'Modifica ' + commessa.name, modalType: 'edit' });
                });
            }

            EventBus.dispatch("hideLoader");
        });

        $('#table_commesse.table').on('click', '.renew_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento Commessa in corso...' });

            const idCommessa = $(e.currentTarget).data('id');
            const commessa: any = await CommesseService.get(idCommessa);
            
            const isFirstLevel = !commessa?.parentJoborder;
            const rateHourly: number = commessa?.rateIsHourly ? 1 : 0;
 
            const _clienti: Customer[] = await ClientiService.getAll();
            const clienti: iFormFieldValue[] = _clienti.map((item: any) => {
                return { key: item.id, value: item.ragionesociale }
            });

            const _attivita: Activity[] = await AttivitaService.getAllDatore();
            const attivita: iFormFieldValue[] = _attivita.map((item: any) => {
                return { key: item.id, value: item.code + ' - ' + item.descrizione }
            });

            this.clienti = clienti;
            this.attivita = attivita;

            await this.selectParentJobOrder(idCommessa, 'edit');

            if (commessa) {
                this.setState({
                    activities: _attivita,
                    formFields: getEditCommessaFormFields(
                        clienti,
                        this.callbackEditJoborderFromCustomer(commessa.customerid, isFirstLevel),
                        this.state.jobOrdersFromClient,
                        attivita,
                        isFirstLevel,
                        this.selectParentJobOrder.bind(this),
                        this.selectActivity.bind(this),
                    ),
                    formInitialValues: {
                        ...commessa, rateIsHourly: rateHourly, rittype: commessa.rittype.id
                    }
                }, () => {
                    this.apiSubmit = CommesseService.renew;
                    this.setState({ showModal: true, modalTitle: 'Rinnova ' + commessa.name, modalType: 'edit' });
                });
            }

            EventBus.dispatch("hideLoader");
        });

        $('#table_commesse.table').on('click', '.stop_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi terminare la commessa ?',
                //text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    EventBus.dispatch("showLoader", { text: 'Termine Commessa in corso...' });
                    const idCommessa = $(e.currentTarget).data('id');
                    await CommesseService.stop(idCommessa);

                    EventBus.dispatch("hideLoader");
                }
            });
        });

        $('#table_commesse.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi confermare la cancellazione ?',
                //text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idCommessa = $(e.currentTarget).data('id');
                    const commessa: any = await CommesseService.delete(idCommessa);
                    if (commessa && typeof commessa.error !== 'undefined') {
                        Swal.fire(
                            commessa.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    async callbackAddJoborderFromCustomer(customerId: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const commesse_all: any = await CommesseService.fromCustomer(customerId);
        let commesse: any = Object.keys(commesse_all).map((key: any) => {
            return { key: commesse_all[key].id, value: commesse_all[key].jobordername }
        });

        this.setState({
            jobOrdersFromClient: commesse,
            formFields: getAddCommessaFormFields(
                this.clienti,
                this.callbackAddJoborderFromCustomer.bind(this),
                commesse,
                this.state.filterActivities,
                this.selectParentJobOrder.bind(this),
            ),
        });

        EventBus.dispatch("hideLoader");
    }

    async openAddModale() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        this.apiSubmit = CommesseService.add;
        const clienti_all: Customer[] = await ClientiService.getAll();
        const clienti: iFormFieldValue[] = clienti_all.map((item: Customer) => {
            return { key: item.id, value: item.ragionesociale }
        });
        //clienti_all.forEach((value: any, key: any) => (clienti[value.id] = value.ragionesociale));

        const _attivita: Activity[] = await AttivitaService.getAllDatore();
        // const attivita: iFormFieldValue[] = _attivita.map((item: Activity) => {
            // return { key: item.id, value: item.code + ' - ' + item.descrizione }
        // });
        const attivita: iFormFieldOption[] = _attivita.map((item: Activity) => {
            return { value: item.id, label: item.code + ' - ' + item.descrizione }
        });

        this.clienti = clienti;
        this.attivita = attivita;

        const filterActivities: iFormFieldValue[] = Array.from(this.attivita);

        this.setState({
            filterActivities
        }, () => {
            this.setState({
                activities: _attivita,
                formFields: getAddCommessaFormFields(
                    clienti,
                    this.callbackAddJoborderFromCustomer.bind(this),
                    this.state.jobOrdersFromClient,
                    this.state.filterActivities,
                    this.selectParentJobOrder.bind(this),
                ),
                showModal: true,
                modalType: 'add',
                modalTitle: 'Aggiungi una nuova commessa'
            });
        });

        EventBus.dispatch("hideLoader");
    }

    getCommesseGiorni(commesse: any, indent: number = 0) {
        let results: any = [];

        commesse.forEach((item: any, index: any) => {
            if ((item.days && item.days > 0) || item.childs.length > 0) {
                if (item.childs.length > 0) {
                    item.childs = this.getCommesseGiorni(
                        item.childs,
                        indent++
                    );
                }

                let color = 'bg-success';
                if (item.percentage > 60) {
                    color = 'bg-danger';
                } else if (item.percentage > 80) {
                    color = 'bg-wargning';
                }
                item.color = color;
                item.indent = indent;
                commesse[index] = item;
                results.push(commesse[index]);
            }
        });

        return results;
    }

    renderCommesseGiorni(commesse_giorni: any) {
        const element: any = [];

        commesse_giorni.forEach((item: any, index: any) => {
            element.push(<tr key={index} style={{
                    fontWeight: item.parentJoborder === "" ? '700' : '400'
                }}>
                <td>
                    {item.parentJoborder !== "" && <i className="fa fa-link" aria-hidden="true"></i>}
                    [{item.code}] {item.name}</td>
                <td>{item.hours_used}(ore)/{item.hours}(ore)</td>
                <td className="w-50">
                    <div className="progress progress-xs">
                        <div className={"progress-bar " + item.color} style={{ "width": item.percentage + "%" }}></div>
                    </div>
                </td>
            </tr>);
            if (item.childs.length > 0) {
                const sub = this.renderCommesseGiorni(item.childs);
                element.push(sub);
            }
        });

        return element;
    }

    render() {
        const { formFields, showModal, modalTitle, modalType, commesse, formInitialValues, currentJobrder } = this.state;
        let jobOrders: any[] = [];
        let commesse_giorni = [];

        if (commesse) {
            jobOrders = commesse[currentJobrder];
            commesse_giorni = this.getCommesseGiorni(commesse.active);
        }

        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h2 className="card-title m-0">Commesse</h2>
                            <button
                                id="add_btn"
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={async () => this.openAddModale()}
                            >
                                <span>Aggiungi commessa</span>
                            </button>
                        </div>
                        <div>
                            <ul className="nav nav-tabs" data-bs-toggle="tabs">
                                <li className="nav-item">
                                    <a
                                        href="#commesse"
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                        onClick={() => this.setState({ currentJobrder: 'active' })}
                                    >
                                        Attive
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="#commesse"
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        onClick={() => this.setState({ currentJobrder: 'expired' })}
                                    >
                                        Scadute
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="#dashboard"
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                    >
                                        Dashboard
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <ModalForm
                                showModal={showModal}
                                title={modalTitle}
                                modalType={modalType}
                                formFields={formFields}
                                initialValues={formInitialValues}
                                apiSubmit={this.apiSubmit}
                                closeCallback={this.closeModal.bind(this)}
                            />
                            <div className="tab-pane active show" id="commesse">
                                <Table
                                    id="table_commesse"
                                    columns={listCommesseColumns}
                                    columnDefs={getListCommesseColumnDefs()}
                                    datas={jobOrders}
                                    buttons={this.buttons}
                                />
                            </div>
                            <div className="tab-pane" id="dashboard">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Utilizzo ore commesse</h3>
                                    </div>
                                    <table className="table card-table table-vcenter">
                                        <thead>
                                            <tr>
                                                <th>Commessa</th>
                                                <th colSpan={2}>Ore</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderCommesseGiorni(
                                                commesse_giorni.filter((item: any) => item.parentJoborder === "")
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}