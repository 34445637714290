/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, MouseEvent } from 'react'
import Swal, { SweetAlertResult } from 'sweetalert2';
import EventBus from '../../common/EventBus';
import {
    IPlanningDetails,
    IPlanningEvent,
    IPlanningFree,
    IPlanningFormFields,
    IPlanningStatus,
    IPlanningEventData,
    IPlanningPeople,
    IPlanningDevice,
    IPlanningVehicle,
    IPLanningLicense,
    IPlanningActivity
} from '../../helpers/interfaces/planning';
import { iFormField, iFormFieldValue, iFormFieldOption } from '../../helpers/interfaces/generic';
import APIPlanningService from '../../services/api/planning.service';
import Modal from '../../common/Modal';
import ModalForm from '../../common/ModalForm';
import { planningChangeStatusFormFields, planningFormFields } from '../../config/formFields/planning';
import commesseService from '../../services/api/commesse.service';
import { TableBSColumn } from '../../common/TableBootstrap';
import attivitaService from '../../services/api/database/attivita.service';
import PlanningMonth, { IPlanningMonth } from './planning-month';
import ObjectToArray from '../../helpers/ObjectToArray';
import { fixDate, getGiornoSettimanaAbbreviato } from '../../helpers/calendar';
import PlanningService from './../../services/planning.service';
import ClientiService from "../../services/api/clienti.service";
import moment from "moment";
import 'moment/locale/it';
import './planning.css';
import { Activity, JoborderResponse, Customer } from '../../helpers/interfaces/joborders';

type Props = {
    history: {
        push(url: string): void,
    }
};

type State = {
    planningColumns: TableBSColumn<IPlanningEvent>[][],
    showModal: boolean,
    showModalForm: boolean,
    planningDetails: IPlanningDetails | null,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    jobOrderList: JoborderResponse['active'],
    jobOrders: iFormFieldOption[],
    activityList: Activity[],
    activities: iFormFieldOption[],
    filterActivities: iFormFieldOption[],
    statuses: iFormFieldOption[],
    people: iFormFieldOption[],
    devices: iFormFieldOption[],
    vehicles: iFormFieldOption[],
    licenses: iFormFieldOption[],
    customers: iFormFieldValue[],
    modalFormType: 'add' | 'edit' | 'change',
    modalFormTitle: string,
    formFields: iFormField[],
    formInitialValues: IPlanningEvent | {},
};

type IApiSubmit = ((id: number, data?: any) => Promise<any>) | ((data: any) => Promise<any>);

export type IPlanningMonthType = 'people' | 'instruments' | 'vehicles' | 'licenses';
export default class PlanningPage extends Component<Props, State>
{
    apiSubmit: IApiSubmit = async() => {};

    priority: iFormFieldOption[] = [
        { value: "U", label: "Urgente" },
        { value: "A", label: "Alta" },
        { value: "M", label: "Media" },
        { value: "B", label: "Bassa" }
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            planningColumns: [],
            showModal: false,
            showModalForm: false,
            startDate: null,
            endDate: null,
            jobOrderList: [],
            jobOrders: [],
            activityList: [],
            activities: [],
            filterActivities: [],
            statuses: [],
            people: [],
            devices: [],
            vehicles: [],
            licenses: [],
            customers: [],
            planningDetails: null,
            modalFormType: 'add',
            modalFormTitle: 'Crea un nuovo evento',
            formFields: [],
            formInitialValues: {}
        }
    }

    async resourceHandler(
        date: moment.Moment,
        type: 'start' | 'end'
    ) : Promise<any> {
        if (type === 'start') {
            const prevDate = this.state.startDate;
            this.setState({ startDate: date }, async() => {
                if (date !== prevDate) {
                    await this.getFreeBetweenTwoDates()
                }
            })
        } else if (type === 'end') {
            const prevDate = this.state.endDate;
            this.setState({ endDate: date }, async() => {
                if (date !== prevDate) {
                    await this.getFreeBetweenTwoDates()
                }
            })
        }
    }

    async getFilterActivities(value: any): Promise<iFormFieldOption[]> {
        const jobOrder = this.state.jobOrderList.filter((item) => {
            return item.id === value
        })[0];

        if (!jobOrder) {
            Swal.fire(
                "Errore",
                "Non è stato possibile recuperare la commessa selezionata",
                "error"
            );

            return [];
        }

        let activities: Activity[] = [];

        if (jobOrder.rittype.id === 1) {
            activities = this.state.activityList.filter(
                (item) => {
                    return item.type === 'O'
                }
            )
        } else if (jobOrder.rittype.id === 2) {
            activities = this.state.activityList.filter(
                (item) => {
                    return item.type === 'C'
                }
            )
        }

        return activities.map((activity) => {
            return {
                value: activity.id,
                label: `${activity.code} - ${activity.descrizione}`
            };
        });
    }

    async selectJobOrder(value: any): Promise<void> {
        await this.getFilterActivities(value).then((filterActivities) => {
            this.setState({
                filterActivities
            }, () => {
                this.setState({
                    formFields: planningFormFields(
                        this.state.people,
                        this.state.devices,
                        this.state.jobOrders,
                        this.state.filterActivities,
                        this.state.vehicles,
                        this.state.licenses,
                        this.state.statuses,
                        this.priority,
                        this.state.customers,
                        false,
                        this.resourceHandler.bind(this),
                        this.selectJobOrder.bind(this)
                    )
                })
            });
        });
    }

    async getFreeBetweenTwoDates() {
        const { startDate, endDate } = this.state;

        if (startDate && endDate) {
            await APIPlanningService.getFreeBetweenTwoDates(
                startDate.format('yyyy-MM-DDTHH:mm:00Z'),
                endDate.format('yyyy-MM-DDTHH:mm:00Z')
            ).then((data: IPlanningFree) => {
                const people: iFormFieldOption[] = ObjectToArray<IPlanningFree['resources'][]>(data.resources).map((person: any) => {
                    return { value: person.id, label: person.fullname };
                });

                const devices: iFormFieldOption[] = ObjectToArray<IPlanningFree['devices'][]>(data.devices).map((device: any) => {
                    return { value: device.id, label: device.fullname };
                });

                const vehicles: iFormFieldOption[] = ObjectToArray<IPlanningFree['vehicles'][]>(data.vehicles).map((vehicle: any) => {
                    return { value: vehicle.id, label: vehicle.fullname };
                });

                const licenses: iFormFieldOption[] = ObjectToArray<IPlanningFree['licenses'][]>(data.licenses).map((license: any) => {
                    return { value: license.id, label: license.fullname };
                });

                this.setState({
                    people,
                    devices,
                    vehicles,
                    licenses
                }, async() => {
                    await this.loadHandler(
                        people,
                        devices,
                        vehicles,
                        licenses,
                        true,
                        false
                    );
                });
            }).catch((e) => { return Promise.reject() });
        } else {
            this.setState({
                people: [],
                devices: [],
                vehicles: [],
                licenses: [],
                formFields: planningFormFields(
                    [],
                    [],
                    this.state.jobOrders,
                    this.state.filterActivities,
                    [],
                    [],
                    this.state.statuses,
                    this.priority,
                    [],
                    false,
                    this.resourceHandler.bind(this),
                    this.selectJobOrder.bind(this)
                ),
            });
        }
    }

    async addHandler() {
        this.setState({
            startDate: null,
            endDate: null,
        }, async () => {
            await this.openModalForm(
                {},
                APIPlanningService.addEvent,
                'add',
                "Crea un nuovo evento"
            );
        });
    }

    async viewHandler(idEvento: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        await APIPlanningService.getEvent(idEvento).then(
            async (planningDetails: IPlanningDetails | null) => {
                if (planningDetails) {
                    this.setState({
                        showModal: true,
                        planningDetails,
                        // Init form fields
                        formFields: planningFormFields(
                            [],
                            [],
                            [],
                            [],
                            [],
                            [],
                            [],
                            this.priority,
                            [],
                            true,
                            this.resourceHandler.bind(this),
                            this.selectJobOrder.bind(this)
                        )
                    });
                } else {
                    Swal.fire('Siamo spiacenti, l\'evento selezionato per la visualizzazione non è stato trovato.')
                }
            },
        );

        EventBus.dispatch("hideLoader");
    };

    async editHandler(idEvento: number) {
        await APIPlanningService.getEvent(idEvento).then(
            async (planningDetails: IPlanningDetails) => {
                if (planningDetails) {
                    this.setState({
                        startDate: moment(planningDetails.start),
                        endDate: moment(planningDetails.end)
                    }, async () => {
                        await this.getFreeBetweenTwoDates()
                            .then(async () => {
                                this.apiSubmit = APIPlanningService.editEvent;

                                let priority: string = "";

                                this.priority.forEach((element) => {
                                    if (element.label === planningDetails.priority) {
                                        priority = element.value.toString()
                                    }
                                });

                                const formInitialValues: IPlanningFormFields = {
                                    id: planningDetails.id,
                                    persons: planningDetails.persons.map((person) => {
                                        return person.id
                                    }),
                                    devices: planningDetails.devices.length ? planningDetails.devices.map((device) => {
                                        return device.id
                                    }) : [],
                                    joborder: planningDetails.joborder.id,
                                    activities: planningDetails.activities.length ? planningDetails.activities.map((activity) => {
                                        return activity.id
                                    }) : [],
                                    start: planningDetails.start,
                                    end: planningDetails.end,
                                    note: planningDetails.note,
                                    stato: planningDetails.stato.id,
                                    vehicles: planningDetails.vehicles.length ? planningDetails.vehicles.map((vehicle) => {
                                        return vehicle.id
                                    }) : [],
                                    licenses: planningDetails.licenses.length ? planningDetails.licenses.map((license) => {
                                        return license.id
                                    }) : [],
                                    customer: planningDetails.customer.id,
                                    priority,
                                    address: planningDetails.address
                                }

                                const people: iFormFieldOption[] = planningDetails.persons.map((person) => {
                                    return { label: person.fullname, value: person.id}
                                });

                                const devices: iFormFieldOption[] = planningDetails.devices.map((device) => {
                                    return { label: device.marca_modello, value: device.id }
                                });

                                const vehicles: iFormFieldOption[] = planningDetails.vehicles.map((vehicle) => {
                                    return { label: `${vehicle.brand} ${vehicle.model} Targa: ${vehicle.plate}`, value: vehicle.id }
                                });

                                const licenses: iFormFieldOption[] = planningDetails.licenses.map((license) => {
                                    return { label: `${license.prodotto.nome} ${license.prodotto.produttore}`, value: license.id }
                                });

                                this.setState({
                                    people: [...this.state.people, ...people],
                                    devices: [...this.state.devices, ...devices],
                                    vehicles: [...this.state.vehicles, ...vehicles],
                                    licenses: [...this.state.licenses, ...licenses],
                                    planningDetails,
                                    formInitialValues,
                                }, async () => {
                                    await this.loadHandler(
                                        this.state.people,
                                        this.state.devices,
                                        this.state.vehicles,
                                        this.state.licenses,
                                        true
                                    );

                                    await this.selectJobOrder(
                                        planningDetails.joborder.id
                                    );

                                    this.setState({
                                        modalFormType: 'edit',
                                        modalFormTitle: `Modifica un'evento`
                                    }, () => {
                                        this.setState({ showModalForm: true });
                                    });
                                });
                            }).catch((e) => {
                                Swal.fire(
                                    'Siamo spiacenti, si è verificato un errore imprevisto durante il caricamento dei dati necessari.'
                                )
                            });
                    })
                } else {
                    Swal.fire('Siamo spiacenti, l\'evento selezionato per la modifica non è stato trovato.')
                }
            },
        );
    };

    async changeStatusHandler(idEvento: number) {
        await APIPlanningService.getEvent(idEvento).then(
            async (planningDetails: IPlanningDetails) => {
                if (planningDetails) {
                    APIPlanningService.status().then((data) => {
                        const statuses: iFormFieldOption[] = data.length ? data.map((status: IPlanningStatus) => {
                            return { value: status.id, label: status.nome };
                        }) : [];

                        this.apiSubmit = APIPlanningService.changeStatusEvent;

                        this.setState({
                            formFields: planningChangeStatusFormFields(statuses),
                            formInitialValues: {
                                id: planningDetails.id,
                                stato: planningDetails.stato.id
                            },
                            modalFormType: 'change',
                            modalFormTitle: `Modifica lo stato dell'evento`
                        }, async() => {
                            this.setState({
                                showModalForm: true
                            })
                        });
                    }).catch((e) => {
                        Swal.fire('Siamo spiacenti, Non è stato possibile caricare la lista degli stati degli eventi.')
                    })
                } else {
                    Swal.fire('Siamo spiacenti, l\'evento selezionato per la modifica dello stato non è stato trovato.')
                }
            },
        );
    }

    async deleteHandler(idEvento: number) {
        Swal.fire({
            title: 'Sei sicuro di voler cancellare questo evento?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#33dd33',
            cancelButtonColor: '#dd3333',
            confirmButtonText: 'Continua',
            cancelButtonText: 'Annulla'
        }).then(async (result: SweetAlertResult<any>) => {
            if (result.isConfirmed) {
                await APIPlanningService.deleteEvent(idEvento)
                    .then(() => {
                        Swal.fire(
                            'Cancellazione dell\'evento effettuata con successo.',
                            '',
                            'success'
                        ).then(() => {
                            window.location.reload();
                        })
                    }).catch((e) => Swal.fire(
                        'Siamo spiacenti, l\'evento selezionato per la cancellazione non è stato trovato.',
                        '',
                        'error'
                    ));
            };
        });
    };

    async openModalForm(
        formInitialValues: IPlanningEvent | {},
        apiSubmit: IApiSubmit,
        modalFormType: 'add' | 'edit' | 'change',
        modalFormTitle: string
    ) {
        this.apiSubmit = apiSubmit;

        await this.loadHandler().then(() => {
            this.setState({
                formInitialValues,
                modalFormType,
                modalFormTitle
            }, async() => {
                this.setState({
                    showModalForm: true,
                    startDate: null,
                    endDate: null,
                });
            });
        }).catch((e) => {
            Promise.reject();
        })
    }

    async loadHandler(
        people: iFormFieldOption[] = [],
        devices: iFormFieldOption[] = [],
        vehicles: iFormFieldOption[] = [],
        licenses: iFormFieldOption[] = [],
        showResourceFields: boolean = false,
        setFilterActivities: boolean = true,
    ) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        await Promise.all([
            commesseService.all(),
            attivitaService.getAllDatore(),
            APIPlanningService.status(),
            ClientiService.getAll()
        ]).then((data: [
            JoborderResponse,
            Activity[],
            IPlanningStatus[],
            Customer[]
        ]) => {
            const jobOrders: iFormFieldOption[] = data[0].active.length ? data[0].active.map((jobOrder: any) => {
                return { value: jobOrder.id, label: jobOrder.name };
            }) : [];

            const activities: iFormFieldOption[] = data[1].length ? data[1].map((activity: any) => {
                return { value: activity.id, label: `${activity.code} - ${activity.descrizione}` };
            }) : [];

            if (setFilterActivities) {
                this.setState({
                    filterActivities: Array.from(activities)
                })
            }

            const statuses: iFormFieldOption[] = data[2].length ? data[2].map((status: IPlanningStatus) => {
                return { value: status.id, label: status.nome };
            }) : [];

            const customers: iFormFieldValue[] = data[3].length ? 
                data[3].map((item: Customer) => { return { key: item.id, value: item.ragionesociale } }) :
                [];

            this.setState({
                jobOrderList: data[0].active,
                jobOrders,
                activityList: data[1],
                activities,
                statuses,
                customers,
            }, () => {
                this.setState({
                    formFields: planningFormFields(
                        people,
                        devices,
                        jobOrders,
                        this.state.filterActivities,
                        vehicles,
                        licenses,
                        statuses,
                        this.priority,
                        customers,
                        showResourceFields,
                        this.resourceHandler.bind(this),
                        this.selectJobOrder.bind(this)
                    ),
                });
            });
        }).catch((error: any) => {
            Swal.fire('Siamo spiacenti, si è verificato un errore imprevisto durante il caricamento dei dati', '', 'error');
            Promise.reject();
        });

        EventBus.dispatch("hideLoader");
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    closeModalForm() {
        this.setState({
            showModalForm: false,
            startDate: null,
            endDate: null
        });
    }
  
    handleSuccess() {
        window.location.hash = "#planning";
        window.location.reload();
    }

    async getMonth(
        year: number,
        month: number,
        type: IPlanningMonthType
    ) : Promise<IPlanningMonth> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        const startDate = moment(`${year}-${fixDate(month + 1)}-01 00:00:00`);
        const daysInMonth = startDate.daysInMonth();
        const endDate = moment(`${year}-${fixDate(month + 1)}-${fixDate(daysInMonth)} 23:59:59`);

        let planningDates: IPlanningEventData[] | null = null;
        if (type === 'people') {
            planningDates = await PlanningService.getPeopleBetweenTwoDates(
                APIPlanningService.getPeopleBetweenTwoDates,
                startDate,
                endDate
            );
        } else {
            planningDates = await PlanningService.getIVLBetweenTwoDates(
                startDate,
                endDate,
                type
            );
        }

        let weekColumns: TableBSColumn<IPlanningEventData>[] = [
            {
                id: "resource_header",
                name: "",
            }
        ];

        let resourceName = '';
        if (type === 'people') {
            resourceName = 'Dipendenti';
        } else if (type === 'instruments') {
            resourceName = 'Strumenti';
        } else if (type === 'vehicles') {
            resourceName = 'Veicoli';
        } else if (type === 'licenses') {
            resourceName = 'Licenze';
        }

        let dayColumns: TableBSColumn<IPlanningEventData>[] = [
            {
                id: "resource",
                name: resourceName,
                data: "resource",
                render: (data: IPlanningEventData['resource']) => {
                    return <span>{data.fullname}</span>
                },
            }
        ];

        let dayCount = 0;
        // const today = new Date();

        for(let i = 1; i <= daysInMonth; i++) {
            dayColumns.push({
                id: `d_${i}`,
                name: `${getGiornoSettimanaAbbreviato(
                    year,
                    month + 1,
                    i
                )} ${i}`,
                data: "plannings",
                className: "href='#'",
                render: (
                    data: IPlanningEventData['plannings'],
                    index: number,
                    row: IPlanningEventData
                ) => {
                    return data.length ? data.map((
                        value: any,
                        index: number
                    ) => {
                        let startValue: string | undefined;
                        let endValue: string | undefined;
                        let description: string | undefined;
                        let color: string | undefined;

                        if (
                            value.type === 'P' ||
                            value.type === 'I'  ||
                            value.type === 'V'  ||
                            value.type === 'L' 
                        ) {
                            startValue = value.start;
                            endValue = value.end;
                            description = `${value.joborder.name} (${value.customer.ragione_sociale})`;
                            color = value.stato.color;
                        } else if (value.type === 'U') {
                            startValue = value.inizio;
                            endValue = value.fine;
                            description = `${value.causale} (${value.stato})`
                            color = value.colore
                        }

                        if (!startValue || !endValue || !description || !color) {
                            return null;
                        }

                        const date = moment(`${year}-${fixDate(month + 1)}-${fixDate(i)}`)
                        const start = moment(startValue);
                        const end = moment(endValue);

                        const show = date.isBetween(start, end, undefined, '[]') ||
                            (start.date() === i && start.month() === month) ||
                            (end.date() === i && end.month() === month);

                        return show ? <div key={index} className='event d-block p-1 pl-2 pr-2 mb-1 rounded small' style={{background: color}}>
                            <div>
                                {value.type === 'P' ? <a className='view_btn' data-id={`${value.id}`} title='Visualizza evento'>
                                    <i className='fa fa-eye' /> Visualizza
                                </a> : <></>}
                            </div>
                            {start.format('HH:mm')}-{end.format('HH:mm')}: {description}
                        </div> : <React.Fragment key={index}></React.Fragment>
                    }) : <></>
                }
            })

            dayCount++;

            const dayDate: moment.Moment = moment(`${year}-${fixDate(month + 1)}-${fixDate(i)}`);
            const dayOfWeek = dayDate.weekday();

            if (dayOfWeek === 6 || i === daysInMonth) {
                const weekOfYear = dayDate.week().toString();

                weekColumns.push(
                    {
                        id: `w_${weekOfYear}`,
                        name: weekOfYear,
                        headerColspan: dayCount
                    }
                )

                dayCount = 0;
            }
        }

        EventBus.dispatch("hideLoader");

        return {
            planningColumns: [
                [...weekColumns],
                [...dayColumns]
            ],
            planningData: planningDates
        }
    }

    async getInterval(
        selectedStartDate: string,
        selectedEndDate: string,
        type: IPlanningMonthType
    ) : Promise<IPlanningMonth> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        const startDate = moment(selectedStartDate);
        const daysInMonth = startDate.daysInMonth();
        const endDate = moment(selectedEndDate);
       
        let dayInterval: any[] = [];
        let monthInterval: any[] = [];
        let yearInterval: any[] = [];

        const _startdate = selectedStartDate.split("-");

        const startYear = Number(_startdate[0]);
        const startMonth = Number(_startdate[1]);
        const startDay = Number(_startdate[2]);

        const _enddate = selectedEndDate.split("-");
        
        const endYear = Number(_enddate[0]);
        const endMonth = Number(_enddate[1]);
        const endDay = Number(_enddate[2]);

        // la logica gestisco solo un intervallo di un mese
        // cioe' date con lo stesso mese o date con mesi successivi
        if (startYear === endYear) {
            if (startMonth === endMonth) {
                for (let i = startDay; i <= endDay; i++) {
                    dayInterval.push(i);  
                    monthInterval.push(startMonth);
                    yearInterval.push(startYear);
                }
            } else {
                for (let i = startDay; i <= daysInMonth; i++) {
                    dayInterval.push(i);
                    monthInterval.push(startMonth);
                    yearInterval.push(startYear);
                }

                // per gestire il msese di febbraio che avendo solo 28/29 giorni
                // consente un intervallo che copre tre mesi
                if (endMonth - startMonth > 1) {
                    const middleYear = Number(_startdate[0]);
                    const middleMonth = Number(_startdate[1]) + 1;

                    let _month = middleMonth.toString();
                    if (_month.length === 1) {_month = '0' + _month;}

                    // const middleDate = _startdate[0] + '-' + _month + '-' + '01';
                    const middleDate = `${middleYear}-${_month}-01`;
                    
                    const middleDaysInMonth = moment(middleDate).daysInMonth();

                    for (let i = 1; i <= middleDaysInMonth; i++) {
                        dayInterval.push(i);
                        monthInterval.push(middleMonth);
                        yearInterval.push(middleYear);
                    }
                }

                for (let i = 1; i <= endDay; i++) {
                    dayInterval.push(i);
                    monthInterval.push(endMonth);
                    yearInterval.push(startYear);
                }
            }
        } else {
            for (let i = startDay; i <= daysInMonth; i++) {
                dayInterval.push(i);
                monthInterval.push(startMonth);
                yearInterval.push(startYear);
            }

            for (let i = 1; i <= endDay; i++) {
                dayInterval.push(i);
                monthInterval.push(endMonth);
                yearInterval.push(endYear);
            }
        }

        let planningDates: IPlanningEventData[] | null = null;
        if (type === 'people') {
            planningDates = await PlanningService.getPeopleBetweenTwoDates(
                APIPlanningService.getPeopleBetweenTwoDates,
                startDate,
                endDate
            );
        } else {
            planningDates = await PlanningService.getIVLBetweenTwoDates(
                startDate,
                endDate,
                type
            );
        }

        let weekColumns: TableBSColumn<IPlanningEventData>[] = [
            {
                id: "resource_header",
                name: "",
            }
        ];

        let resourceName = '';
        if (type === 'people') {
            resourceName = 'Dipendenti';
        } else if (type === 'instruments') {
            resourceName = 'Strumenti';
        } else if (type === 'vehicles') {
            resourceName = 'Veicoli';
        } else if (type === 'licenses') {
            resourceName = 'Licenze';
        }

        let dayColumns: TableBSColumn<IPlanningEventData>[] = [
            {
                id: "resource",
                name: resourceName,
                data: "resource",
                render: (data: IPlanningEventData['resource']) => {
                    return <span>{data.fullname}</span>
                }
            }
        ];

        let dayCount = 0;

        dayInterval.map((day: number, i: number) => {
            dayColumns.push({
                id: `d_${day}`,
                name: `${getGiornoSettimanaAbbreviato(
                    yearInterval[i],
                    monthInterval[i],
                    day
                )} ${day}`,
                data: "plannings",
                render: (
                    data: IPlanningEventData['plannings'],
                    index: number,
                    row: IPlanningEventData
                ) => {
                    return data.length ? data.map((
                        value: any,
                        index: number
                    ) => {
                        let startValue: string | undefined;
                        let endValue: string | undefined;
                        let description: string | undefined;
                        let color: string | undefined;

                        if (
                            value.type === 'P' ||
                            value.type === 'I'  ||
                            value.type === 'V'  ||
                            value.type === 'L' 
                        ) {
                            startValue = value.start;
                            endValue = value.end;
                            description = `${value.joborder.name} (${value.customer.ragione_sociale})`;
                            color = value.stato.color;
                        } else if (value.type === 'U') {
                            startValue = value.inizio;
                            endValue = value.fine;
                            description = `${value.causale} (${value.stato})`
                            color = value.colore
                        }

                        if (!startValue || !endValue || !description || !color) {
                            return null;
                        }

                        const date = moment(`${yearInterval[i]}-${fixDate(monthInterval[i])}-${fixDate(day)}`)
                        const start = moment(startValue);
                        const end = moment(endValue);

                        const show = date.isBetween(start, end, undefined, '[]') ||
                            (start.date() === day && start.month() === (monthInterval[i] - 1) && start.year() === yearInterval[i]) ||
                            (end.date() === day && end.month() === (monthInterval[i] - 1) && end.year() === yearInterval[i]);

                        return show ? <div key={index} className='event d-block p-1 pl-2 pr-2 mb-1 rounded small' style={{background: color}}>
                            <div>
                                {value.type === 'P' ? <a className='view_btn' data-id={`${value.id}`} title='Visualizza evento'>
                                    <i className='fa fa-eye' /> Visualizza
                                </a> : <></>}
                            </div>
                            {start.format('HH:mm')}-{end.format('HH:mm')}: {description}
                        </div> : <React.Fragment key={index}></React.Fragment>
                    }) : <></>
                }
            })

            dayCount++;

            const dayDate: moment.Moment = moment(`${yearInterval[i]}-${fixDate(monthInterval[i])}-${fixDate(day)}`);
            const dayOfWeek = dayDate.weekday();

            if (dayOfWeek === 6 || (day === dayInterval[dayInterval.length - 1] && monthInterval[i] === monthInterval[monthInterval.length - 1])) {

                const weekOfYear = dayDate.week().toString();

                weekColumns.push(
                    {
                        id: `w_${weekOfYear}`,
                        name: weekOfYear,
                        headerColspan: dayCount
                    }
                )

                dayCount = 0;
            }

            return null
        })

        EventBus.dispatch("hideLoader");

        return {
            planningColumns: [
                [...weekColumns],
                [...dayColumns]
            ],
            planningData: planningDates
        }
    }

    renderModalContent() {
        const { planningDetails } = this.state;

        return planningDetails ? <>
            <hr />
            <div id="planning_details_joborder">
                <strong>Commessa:</strong>{' '}
                {/*<a className="text-primary" onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/contratti/commesse/' + planningDetails.joborder.id);
                }}>*/}{planningDetails.joborder.name} ({planningDetails.joborder.code}){/*</a>*/}
            </div>
            <br />
            <div id="planning_details_customer">
                <strong>Cliente:</strong>{' '}
                {/*<a className="text-primary" onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/contratti/clienti/' + planningDetails.customer.id);
                }}>*/}{planningDetails.customer.ragione_sociale}{/*</a>*/}
            </div>
            <br />
            <div id="planning_details_activities">
                <strong>Attività:</strong>{' '}
                {planningDetails.activities.length ? planningDetails.activities.map(
                    (activity: IPlanningActivity, index: number) => {
                        return <React.Fragment key={index}>
                            {/* <a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/contratti/attivita/' + activity.id);
                            }}>*/}{activity.descrizione}{/*</a> */}
                            {index < planningDetails.activities.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_start">
                <strong>Data inizio:</strong>{' '}
                {moment(planningDetails.start).format('DD/MM/YYYY HH:mm')}
            </div>
            <br />
            <div id="planning_details_end">
                <strong>Data fine:</strong>{' '}
                {moment(planningDetails.end).format('DD/MM/YYYY HH:mm')}
            </div>
            <br />
            <div id="planning_details_people">
                <strong>Persone:</strong>{' '}
                {planningDetails.persons.length ? planningDetails.persons.map(
                    (person: IPlanningPeople, index: number) => {
                        return <React.Fragment key={index}>
                            <a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/organico/dettaglio-personale/' + person.id);
                            }}>{person.fullname}</a>
                            {index < planningDetails.persons.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_devices">
                <strong>Dispositivi:</strong>{' '}
                {planningDetails.devices.length ? planningDetails.devices.map(
                    (device: IPlanningDevice, index: number) => {
                        return <React.Fragment key={index}>
                            <a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/magazzino/dispositivi/dettaglio-dispositivo/' + device.id);
                            }}>{device.marca_modello} ({device.serial_number})</a>
                            {index < planningDetails.devices.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_vehicles">
                <strong>Veicoli:</strong>{' '}
                {planningDetails.vehicles.length ? planningDetails.vehicles.map(
                    (vehicle: IPlanningVehicle, index: number) => {
                        return <React.Fragment key={index}>
                            {/*<a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/magazzino/veicoli/' + vehicle.id);
                        }}>*/}{vehicle.brand} {vehicle.model} ({vehicle.plate}){/*</a>*/}
                            {index < planningDetails.vehicles.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_licenses">
                <strong>Licenze:</strong>{' '}
                {planningDetails.licenses.length ? planningDetails.licenses.map(
                    (license: IPLanningLicense, index: number) => {
                        return <React.Fragment key={index}>
                            <a className="text-primary" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/magazzino/licenze/dettaglio-licenza/' + license.id);
                            }}>{license.prodotto.nome} ({license.identificativo})</a>
                            {index < planningDetails.licenses.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_status">
                <strong>Stato:</strong>{' '}
                {planningDetails.stato.nome}
                <a className='btn btn-primary px-2 py-1 ms-3' onClick={async(e) => this.changeStatusHandler(planningDetails.id)}>Cambia</a>
            </div>
            <br />
            <div id="planning_details_priority">
                <strong>Priorità:</strong>{' '}
                {planningDetails.priority}
            </div>
            <br />
            <div id="planning_details_address">
                <strong>Indirizzo:</strong>{' '}
                {planningDetails.address}
            </div>
            <br />
            <div id="planning_details_note">
                <strong>Note:</strong>{' '}
                {planningDetails.note}
            </div>
            <hr />
            <a className='btn btn-warning mb-3 me-3' onClick={async(e) => this.editHandler(planningDetails.id)}>Modifica evento</a>
            <a className='btn btn-danger mb-3' onClick={async(e) => this.deleteHandler(planningDetails.id)}>Elimina evento</a>
        </> : <h2>Evento non trovato.</h2>
    }

    render() {
        const { showModal, showModalForm, modalFormTitle, modalFormType, formFields, formInitialValues } = this.state;

        return  <React.Fragment>
            <Modal
                showModal={showModal}
                title={`Dettagli dell'evento`}
                type={'details'}
                closeCallback={this.closeModal.bind(this)}
                content={this.renderModalContent()}
            />
            <ModalForm
                id={`modal-form-${modalFormType}`}
                showModal={showModalForm}
                title={modalFormTitle}
                modalType={modalFormType}
                formFields={formFields}
                initialValues={formInitialValues}
                apiSubmit={this.apiSubmit}
                closeCallback={this.closeModalForm.bind(this)}
                successAction={this.handleSuccess.bind(this)}
            />
            <div id="planning" className='custom-container'>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                            <h2 className="card-title">Planning</h2>
                            <a
                                    id="add_btn"
                                    href="#add"
                                    className="btn btn-outline-primary"
                                    onClick={async (e: MouseEvent) => {
                                        e.preventDefault();
                                        this.addHandler();
                                    }}
                                >
                                <span>Aggiungi evento</span>
                            </a>                
                        </div>
                        <PlanningMonth
                            getMonth={this.getMonth.bind(this)}
                            getInterval={this.getInterval.bind(this)}
                            viewHandler={this.viewHandler.bind(this)}
                            type='planning'
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}