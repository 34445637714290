import { Formik, Form, Field, ErrorMessage } from 'formik';
import React, { Component } from 'react'
import Swal from 'sweetalert2';
import { strumentiFormFields, IStrumentoForm, mapListsData } from '../../../config/formFields/magazzino/magazzino-strumenti';
import { IMagazzinoContrattoNoleggio } from '../../../helpers/interfaces/magazzino/magazzino-contratti-noleggi';
import { IMagazzinoStrumentoBody } from '../../../helpers/interfaces/magazzino/magazzino-strumenti';
import magazzinoContrattiNoleggiService from '../../../services/api/magazzino/magazzino-contratti-noleggi.service';
import magazzinoStrumentiService from '../../../services/api/magazzino/magazzino-strumenti.service';
import statoStrumentiService from '../../../services/api/magazzino/magazzino-stato-strumenti.service';
import tipologiaStrumentiService from '../../../services/api/magazzino/magazzino-tipologia-strumenti.service';
import categoriaStrumentiService from '../../../services/api/magazzino/magazzino-categoria-strumenti.service';
import * as Yup from 'yup';
import DatePickerForm from '../../../common/DatePickerForm';
import EventBus from '../../../common/EventBus';
import { iFormField, iFormFieldValue } from '../../../helpers/interfaces/generic';
import { IMagazzinoStatoStrumento } from '../../../helpers/interfaces/magazzino/magazzino-stato-strumenti';
import { IMagazzinoTipologiaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-strumenti';
import { IMagazzinoCategoriaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-categoria-strumenti';
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';

type Props = {
    history: {
        push(url: string): void;
    }
    match: {
        params: {
            id: string
        };
    }
}

type State = {
    stati: IMagazzinoStatoStrumento[],
    tipologie: IMagazzinoTipologiaStrumento[],
    categorie: IMagazzinoCategoriaStrumento[],
    contratti: IMagazzinoContrattoNoleggio[],
    formFields: iFormField[],
    loading: boolean,
    formInitialValues: IStrumentoForm,
    attachmentsToUpload: File[]
};

export default class MagazzinoNuovoStrumento extends Component<Props, State> {

    inputFileRef = React.createRef()

    constructor(props: Props) {
        super(props)

        this.state = {
            stati: [],
            tipologie: [],
            categorie: [],
            contratti: [],
            formFields: [],
            loading: false,
            formInitialValues: {} as IStrumentoForm,
            attachmentsToUpload: []
        }

        this.handleAddStrumento = this.handleAddStrumento.bind(this)
        this.setValidations = this.setValidations.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
    }

    // method: initializing the form values
    initFormValues(): IStrumentoForm {
        return {
            stato_nome: '',
            tipologia_nome: '',
            categoria_nome: '',
            categoria_id: 0,
            internalid: '',
            code: '',
            marca_modello: 'n.d',
            serial_number: 'n.d',
            builder: '',
            misuramentrange: '',
            calibrationdate: '',
            frequency: '',
            expiration: '',
            location: '',
            purchase: '',
            warranty: '',
            verify: '',
            acceptability: '',
            disponibile: false,
            noleggio: '',
            contrattonoleggio: ''
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento strumento in corso...' });

        const statiPromise = statoStrumentiService.getAll();
        const tipologiePromise = tipologiaStrumentiService.getAll();
        const categoriePromise = categoriaStrumentiService.getAll();
        const contrattiPromise = magazzinoContrattiNoleggiService.getAll();

        // calling the needed data
        await Promise.all([statiPromise, tipologiePromise, categoriePromise, contrattiPromise])
            .then(
                values => {
                    this.setState(
                        {
                            stati: values[0],
                            tipologie: values[1],
                            categorie: values[2],
                            contratti: values[3]
                        },
                        () => {
                            const stati = mapListsData(this.state.stati);
                            const tipologie = mapListsData(this.state.tipologie);
                            const categorie = mapListsData(this.state.categorie);
                            const contratti = mapListsData(this.state.contratti);
            
                            this.setState(
                                {
                                    formFields: strumentiFormFields(stati, tipologie, categorie, contratti),
                                    formInitialValues: this.initFormValues()
                                },
                                () => {
                                    EventBus.dispatch("hideLoader");
                                }
                            );
                        }
                    )
                },
                error => {
                    EventBus.dispatch("hideLoader");
                }
            );
    }

    // method: handling add device actions
    async handleAddStrumento(values: IStrumentoForm) {
        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        const stato = this.state.stati.find(stato => stato.nome === values.stato_nome);
        const tipologia = this.state.tipologie.find(tipologia => tipologia.nome === values.tipologia_nome);
        const categoria = this.state.categorie.find(categoria => categoria.nome === values.categoria_nome);
        const contratto = this.state.contratti.find(contratto => contratto.nome + " - " + contratto.codice === values.contrattonoleggio);

        let formData = new FormData();
        const { attachmentsToUpload } = this.state;
        attachmentsToUpload.forEach((file, i) => formData.append("attachment_" + i, file))

        let strumentoBody: IMagazzinoStrumentoBody = {
            stato_id: stato ? stato.id : undefined,
            tipologia_id: tipologia ? tipologia.id : 0,
            categoria_id: categoria ? categoria.id : 0,
            // categoria_id: values.categoria_id,
            code: values.code,
            internalid: values.internalid,
            marca_modello: values.marca_modello,
            serial_number: values.serial_number,
            builder: values.builder,
            misuramentrange: values.misuramentrange,
            calibrationdate: values.calibrationdate,
            frequency: values.frequency,
            expiration: values.expiration,
            location: values.location,
            purchase: values.purchase,
            warranty: values.warranty,
            verify: values.verify,
            acceptability: values.acceptability,
            disponibile: values.disponibile,
            noleggio: values.noleggio === "Sì" ? 1 : (values.noleggio === "No" ? 0 : null),
            contrattinoleggio_id: contratto ? contratto.id : undefined,
        }

        for (const key of Object.keys(strumentoBody) as (keyof IMagazzinoStrumentoBody)[]) {
            formData.append(key, strumentoBody[key]?.toString() ?? '');
        }

        // handle add call
        await magazzinoStrumentiService.add(formData).then(
            response => {
                EventBus.dispatch("hideLoader");
                Swal.fire(
                    'Operazione eseguita.',
                    '',
                    'success'
                ).then(
                    () => this.setState(
                        {
                            loading: false
                        },
                        () => this.props.history.push("/magazzino/strumenti")
                    )
                );
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    // method: saving files in the state
    handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { attachmentsToUpload } = this.state
        let files = event.currentTarget.files ? Array.from(event.currentTarget.files) : []
        if (attachmentsToUpload.length === 0) {
            this.setState({ attachmentsToUpload: files }, () => { event.target.value = "" })
        } else {
            let concatArray = files.concat(attachmentsToUpload)
            // removing duplicates after merging new and old files
            let attachments = concatArray.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.name === value.name &&
                    t.size === value.size &&
                    t.type === value.type &&
                    t.lastModified === value.lastModified
                ))
            )
            this.setState({ attachmentsToUpload: attachments }, () => { event.target.value = "" })
        }
    }

    // method: rendering form filed based on type
    renderFormFields(field: iFormField, setFieldValue: (name: string, value: any, shouldValidate?: boolean | undefined) => void): JSX.Element {
        switch (field.type) {
            case 'select':
                return (
                    <Field as={field.type} name={field.name} className={field.class}>
                        <option key={''} value={''}>---</option>
                        {field.values?.map((item: iFormFieldValue) => {
                            return <option key={item.key} value={item.value}>{item.value}</option>
                        })}
                    </Field>
                );
            case 'date':
                return (<DatePickerForm name={field.name} className={field.class} onChange={(state) => setFieldValue(field.name, state.toSend)} />);
            case 'file':
                const { attachmentsToUpload } = this.state
                return (
                    <React.Fragment>
                        <Field id={field.name} name={field.name} multiple={true} className={field.class} type={field.type} onChange={this.handleFileChange} />
                        {
                            attachmentsToUpload.length !== 0 ? (
                                <div className='mt-3'>
                                    <h4>File da caricare</h4>
                                    {attachmentsToUpload.map((file, index) => (
                                        <div className='d-flex align-items-center mb-1'>
                                            <button style={buttonsStyle} className='btn btn-outline-danger rounded-circle me-2' type='button' onClick={() => {
                                                let files = attachmentsToUpload
                                                files.splice(files.indexOf(file), 1)
                                                this.setState({ attachmentsToUpload: files })
                                            }}>
                                                <i style={iconsStyle} className="fa fa-trash-o" aria-hidden="true"></i>
                                            </button>
                                            <span key={index + '_' + Date.now()}>
                                                {file.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            ) : ''
                        }

                    </React.Fragment>
                );
            default:
                return (<Field name={field.name} type={field.type} className={field.class} />);
        }
    }

    render() {
        const { loading, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof IStrumentoForm])

        return <div className="container py-3">
            {
                formFields.length > 0 && <React.Fragment>

                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleAddStrumento}
                    >
                        {({ setFieldValue }) => (
                            <Form className="card">
                                <div className="card-body">
                                    <div className='col-12 pb-2'>
                                        <h3>Nuovo strumento</h3>
                                    </div>

                                    {formFields.map(
                                        (field: iFormField, key) => {
                                            return <div className="mb-3" key={key}>
                                                <div className='col-12'>
                                                    <label className="form-label">{field.label}</label>
                                                    {
                                                        this.renderFormFields(field, setFieldValue)
                                                    }
                                                    <ErrorMessage
                                                        name={field.name}
                                                        component="div"
                                                        className="alert alert-danger"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    )}
                                    <div className="col-12 d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {
                                                loading && <span className="spinner-border spinner-border-sm mr-1"></span>
                                            }
                                            <span>Salva</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </React.Fragment>
            }

        </div>

    }
}