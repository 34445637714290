import React, { Component } from "react";
import RapportiniGiornaliero from './RapportiniGiornaliero';
import RapportiniMensile from './RapportiniMensile';
import RapportiniPresenze from './RapportiniPresenze';
import RapportiniCommesse from './RapportiniCommesse';
import RapportiniPerData from './RapportiniPerData';
import { Field, FormikProps } from "formik";
import eventBus from "../../common/EventBus";
import PersonService from "../../services/api/person.service";
import CommesseService from "../../services/api/commesse.service";
import ReportsService from "../../services/api/reports.service";
import { openBase64NewTab } from "../../common/Base64";
import Swal from "sweetalert2";
import { Tab } from "../../common/TabComponent";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";
import { JoborderObject, ReportType } from "../../helpers/interfaces/user";
import { JoborderResponse } from "../../helpers/interfaces/joborders";

type Props = {};

type State = {
    persons: iFormFieldValue[],
    checkboxDisabled: boolean,
    selectMonthDisabled: boolean,
    joborders: iFormFieldValue[],
    joborders_all: {[key: number]: JoborderObject}[],
    typeList: any[],
    loading: boolean
}
export default class Reports extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            persons: [],
            checkboxDisabled: false,
            selectMonthDisabled: false,
            joborders: [],
            joborders_all: [],
            typeList: [],
            loading: false
        }

        this.handleGenerateRal = this.handleGenerateRal.bind(this);
    }

    async componentDidMount() {
        eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const person_all = await PersonService.getAllForSearch();
        const persons = person_all.data.map(item => { return { key: item.id, value: item.nominativo } });
        const checkboxDisabled = this.state.checkboxDisabled;
        const selectMonthDisabled = this.state.selectMonthDisabled;

        const joborders_get_all: JoborderResponse = await CommesseService.getAll();
        let joborders_active: iFormFieldValue[] = joborders_get_all.active.map((item: any) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' } }).sort(
            (j1: iFormFieldValue, j2: iFormFieldValue) => j1.value.localeCompare(j2.value)
        );
        let joborders_expired: iFormFieldValue[] = joborders_get_all.expired.map((item: any) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' } }).sort(
            (j1: iFormFieldValue, j2: iFormFieldValue) => j1.value.localeCompare(j2.value)
        );

        const joborders = joborders_active.concat(joborders_expired);
        const joborders_all: any[] = joborders_get_all.active.concat(joborders_get_all.expired);

        const _typeList: ReportType[] = await ReportsService.getTablenameList();
        const typeList: iFormFieldValue[] = _typeList.map((item: ReportType) => { return { key: item.id, value: item.type } });

        this.setState({ persons, checkboxDisabled, selectMonthDisabled, joborders, joborders_all, typeList });
        eventBus.dispatch("hideLoader");
    }

    handleChange(personId: any) {
        personId ? this.setState({ checkboxDisabled: true }) : this.setState({ checkboxDisabled: false });
    }

    handleFormFields(event: any) {
        event && event.length === 4 ? this.setState({ selectMonthDisabled: false }) : this.setState({ selectMonthDisabled: true });
    }

    renderField(item: iFormField, _checkboxDisabled: boolean, key: number, formik: FormikProps<any>) {
        const displayItem = ((item.showFromFieldName && item.showFromFieldValue && item.showFromFieldValue.includes(Number(formik.values[item.showFromFieldName]))) || typeof item.showFromFieldName == 'undefined');

        switch (item.type) {
            case 'select':
                return displayItem && <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <Field as={item.type} name={item.name} className={item.class} onChange={async (event: any) => {

                            this.handleChange(event.target.value);
                            this.handleFormFields(event.target.value);

                            formik.setFieldValue(item.name, event.target.value);
                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.target.value, formik);
                            }
                        }}>
                            <option key={''} value={''}>{item.value ?? 'Tutti'}</option>
                            {item.values && item.values.map((option: iFormFieldValue) => {
                                return <option key={option.key} value={option.key}>{option.value}</option>
                            })}
                        </Field>
                    </div>
                </React.Fragment>
            case 'file':
                return displayItem && <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <input name={item.name} type={item.type} className={item.class} onChange={(event: any) => {
                            formik.setFieldValue(item.name, event.currentTarget.files[0]);
                        }} />
                    </div>
                </React.Fragment>
            case 'radio':
                return displayItem && <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        {item.values && item.values.map((value: iFormFieldValue) => {
                            return <label key={value.key}>
                                <Field type="radio" name={item.name} value={value} />
                                {value}
                            </label>
                        })}
                    </div>
                </React.Fragment>
            case 'checkbox':
                return displayItem && <label className="form-check form-switch">
                    <Field name={item.name} type={item.type} className={item.class} disabled={false} autoFocus={false} />
                    <span className="form-check-label form-label" style={{ 'textAlign': 'left' }}>{item.label}</span>
                </label>
            default:
                //return <Field innerRef={(el: any) => this.innerRefs[item.name] = el} name={item.name} type={item.type} className={item.class} />
                return displayItem && <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <Field name={item.name} type={item.type} className={item.class} />
                    </div>
                </React.Fragment>
        }
    }

    handleGenerateRal() {
        this.setState(
            {
                loading: true
            },
            async () => {
                eventBus.dispatch("showLoader", { text: 'Generazione report in corso...' });
                const report: any = await ReportsService.ral();
                if (typeof report?.body !== 'undefined') {
                    openBase64NewTab(report);
                    this.setState({
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                    }, () => {
                        Swal.fire({
                            title: 'Errore',
                            text: 'Si è verificato un errore durante la generazione del report.',
                            icon: 'error',
                        });
                    });
                }
                eventBus.dispatch("hideLoader");
            })
    }

    render() {
        // const { persons, checkboxDisabled ,joborders, joborders_all, loading } = this.state;
        const { persons, checkboxDisabled, joborders, joborders_all, typeList } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">
                            Reports
                        </h2>
                        <Tab useState={true} tabs={[
                            {
                                id: 'rapportini_giornaliero',
                                label: 'Rapportino giornaliero',
                                content: <RapportiniGiornaliero persons={persons} checkboxDisabled={checkboxDisabled} renderField={this.renderField.bind(this)} />,
                                isActive: true
                            },
                            {
                                id: 'rapportini_mensile',
                                label: 'Rapportino mensile',
                                content: <RapportiniMensile persons={persons} checkboxDisabled={checkboxDisabled} renderField={this.renderField.bind(this)} />,
                                isActive: false
                            },
                            // {
                            //     id: 'rapportini_annuale',
                            //     label: 'Rapportino annuale',
                            //     content: <RapportiniAnnuale persons={persons} checkboxDisabled={checkboxDisabled} renderField={this.renderField.bind(this)} />
                            // },
                            {
                                id: 'rapportini_per_data',
                                label: 'Rapportino per data',
                                content: <RapportiniPerData typeList={typeList} renderField={this.renderField.bind(this)} />
                            },
                            {
                                id: 'rapportini_presenze',
                                label: 'Rapportino presenze',
                                content: <RapportiniPresenze persons={persons} checkboxDisabled={checkboxDisabled} renderField={this.renderField.bind(this)} />
                            },
                            {
                                id: 'commesse',
                                label: 'Commesse',
                                content: <RapportiniCommesse persons={persons} joborders={joborders} joborders_all={joborders_all} renderField={this.renderField.bind(this)} />
                            },
                            // {
                            //     id: 'materiale_assegnato',
                            //     label: 'Materiale assegnato',
                            //     content: <RapportiniMateriale renderField={this.renderField} />
                            // },
                            // {
                            //     id: 'anagrafica',
                            //     label: 'Anagrafica',
                            //     content: <RapportiniAnagrafica renderField={this.renderField} />
                            // },
                            // {
                            //     id: 'ral_costi',
                            //     label: 'RAL e costi',
                            //     content: (
                            //         <div className="px-3 pt-3 d-flex justify-content-end">
                            //             <button className="btn btn-primary" disabled={loading} onClick={() => this.handleGenerateRal()}>
                            //                 {loading && (
                            //                     <span className="spinner-border spinner-border-sm me-1"></span>
                            //                 )}
                            //                 <span>Genera</span>
                            //             </button>
                            //         </div>
                            //     )
                            // }
                        ]} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}