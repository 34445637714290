import { AxiosError, AxiosResponse } from 'axios';
import axios from '../api';
import {
  IPlanning,
  IPlanningDetails,
  IPlanningEditResponse,
  IPlanningRequest,
  IPlanningFree,
  IPlanningStatus,
  IPlanningIVL
} from '../../helpers/interfaces/planning';
const API_URL = process.env.REACT_APP_API_URL;

class PlanningService {
  async getPeopleBetweenTwoDates(startDate: string, endDate: string): Promise<IPlanning> {
    try {
      return await axios.get(
        API_URL + `datore/planning/dates/${startDate}/${endDate}`
      ).then((response: AxiosResponse<IPlanning>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async getBetweenTwoDatesDipendente(startDate: string, endDate: string): Promise<IPlanning> {
    try {
      return await axios.get(
        API_URL + `dipendente/planning/dates/${startDate}/${endDate}`
      ).then((response: AxiosResponse<IPlanning>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async getFreeBetweenTwoDates(start: string, end: string): Promise<IPlanningFree> {
    try {
      return await axios.post(
        API_URL + `datore/planning/free`,
        {
          start,
          end
        }
      ).then((response: AxiosResponse<any>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async getDayDipendente(date: string): Promise<IPlanning> {
    try {
      return await axios.get(
        API_URL + `dipendente/planning/day/${date}`
      ).then((response: AxiosResponse<IPlanning>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async getEvent(id: number): Promise<IPlanningDetails> {
    try {
      return await axios.get(
        API_URL + `datore/planning/get/${id}`
      ).then((response: AxiosResponse<IPlanningDetails>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async getEventDipendente(id: number): Promise<IPlanningDetails> {
    try {
      return await axios.get(
        API_URL + `dipendente/planning/get/${id}`
      ).then((response: AxiosResponse<IPlanningDetails>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async addEvent(data: IPlanningRequest): Promise<IPlanning> {
    try {
      return await axios.post(
        API_URL + `datore/planning/add`,
        data
      ).then((response: AxiosResponse<any>) => {
        if (!response.data.error) {
          return response.data;
        } else {
          return Promise.reject();
        }
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async editEvent(id: number, data: IPlanningRequest): Promise<IPlanningEditResponse> {
    try {
      return await axios.put(
        API_URL + `datore/planning/update/${id}`,
        data
      ).then((response: AxiosResponse<any>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async changeStatusEvent(id: number, data: {stato: number}): Promise<any> {
    try {
      return await axios.put(
        API_URL + `datore/planning/change/${id}/${data.stato.toString()}`
      ).then((response: AxiosResponse<any>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async setCompleteEventDipendente(id: number): Promise<any> {
    try {
      return await axios.put(
        API_URL + `dipendente/planning/complete/${id}`
      ).then((response: AxiosResponse<any>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async deleteEvent(id: number): Promise<IPlanningEditResponse> {
    try {
      return await axios.delete(
        API_URL + `datore/planning/${id}`
      ).then((response: AxiosResponse<any>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }

  async status(): Promise<IPlanningStatus[]> {
    try {
      return await axios.get(
        API_URL + `datore/planning/status`
      ).then((response: AxiosResponse<IPlanningStatus[]>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }
  async getIVLDates(startDate: string, endDate: string): Promise<IPlanningIVL> {
    try {
      return await axios.get(
        API_URL + `datore/planning/ivldates/${startDate}/${endDate}`
      ).then((response: AxiosResponse<IPlanningIVL>) => {
        return response.data;
      }).catch((error: AxiosError) => {
        return Promise.reject();
      });
    } catch (error: any) {
      return await Promise.reject();
    }
  }
}

export default new PlanningService();