import React, { Component } from "react";
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import { checkFestivo, getNomeMese, getGiornoSettimana, giorniLavorativi } from './../../../helpers/calendar';
import RendicontazioniService from "../../../services/api/rendicontazioni.service";
import PersonService from "../../../services/api/person.service";
import CausaliService from "../../../services/api/database/causali.service";
import reportings from './helper/reportings';
import DocumentiService from "../../../services/api/documenti.service";
import { base64Decode, openBase64NewTab } from '../../../common/Base64';
import ModalForm from '../../../common/ModalForm';
import { getModalRendicontazioneFields } from "../../../config/formFields/rendicontazione";
import { iconsStyle, resetButtonsStyle } from "../../../helpers/settings/buttons-icons-styles";
import { iFormField, iFormFieldOption } from "../../../helpers/interfaces/generic";
import { PersonJoborder } from "../../../helpers/interfaces/person";
import CopyToClipboard from "react-copy-to-clipboard";
import { statusColors } from "../../../helpers/settings/status-settings";
import { iCausale, iOperatore, iStrumento, iTrasferta } from "../../../helpers/interfaces/rendicontazione";
import { JoborderObject } from "../../../helpers/interfaces/user";
import { iRendicontazione, iReportingInitialFormData } from "./helper/reportings";
import { FormikProps } from "formik";
import { IPlanningDevice } from "../../../helpers/interfaces/planning";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import moment from "moment";
import 'moment/locale/it';
import './style.css';

const $ = require('jquery');

type Props = {
    match: any
};

type State = {
    year: number,
    month: number,
    giorniSettimana: any,
    giorniMese: any,
    giorniVuoti: any,
    oreMese: number,
    showModal: boolean,
    modalTitle: string;
    modalType: string,
    formFields: Array<iFormField>,
    formInitialValues: iReportingInitialFormData,
    formSubmit: (formData: any, formik: FormikProps<any>) => any;
    errorMessage: string | null;
    copied: boolean;
}

type iCommesse = {
    id: number,
    value: string,
    code: string,
    parent: Array<any> | null
}

export default class DettaglioRendicontazione extends Component<Props, State> {
    giorniSettimana: string[];
    initialValues: iReportingInitialFormData;

    constructor(props: Props) {
        super(props);

        this.giorniSettimana = [
            'Dom', 'Lun', 'Mar', 'Mer', 'Giov', 'Ven', 'Sab'
        ];

        this.initialValues = {
            ora_fine_hh: '0',
            ora_fine_mm: '00',
            ora_inizio_hh: '0',
            ora_inizio_mm: '00',
            tempo_viaggio_hh: '0',
            tempo_viaggio_mm: '0',
        }

        this.state = {
            year: 0,
            month: 0,
            giorniVuoti: [],
            giorniSettimana: [],
            giorniMese: [],
            oreMese: 0,
            showModal: false,
            modalTitle: 'Rendicontazione',
            modalType: 'add',
            formFields: [],
            formInitialValues: this.initialValues,
            errorMessage: null,
            copied: false,
            formSubmit: reportings.addSubmitModal.bind(this),
        }
    }

    mapCommesse = (joborders: JoborderObject[], parent: any = null): any[] => {
        let results: iCommesse[] = [];
        let subRes: iCommesse[] = [];

        joborders.forEach((item: any) => {
            const lvl = typeof item.lvl === 'undefined' ? 0 : item.lvl;
            const char = "----".repeat(lvl);
            const name = typeof item.name === 'undefined' ? item.jobordername : item.name;
            const code = !parent ? item.code : item.identificationcode ?? '';

            results.push({ id: item.id, value: `${char} ${name}`, code, parent });

            if (typeof item.childs !== 'undefined' && item.childs.length > 0) {
                subRes = this.mapCommesse(item.childs, { id: item.id, value: name });

                subRes.forEach((item: any) => {
                    results.push(item);
                });
            }
        });

        return results;
    }

    async componentDidMount() {
        const rendicontazione = this.props.match.params.rendicontazione.split('-');
        if (rendicontazione.length === 3) {
            const personId = rendicontazione[0];
            const year = rendicontazione[1];
            const month = rendicontazione[2];

            const data = moment(year + '-' + month + '-01');
            const giorno_settimana = data.day();
            const giorni_1 = this.giorniSettimana.slice(0, giorno_settimana);
            const giorni_2 = this.giorniSettimana.slice(giorno_settimana, this.giorniSettimana.length);

            const daysInMonth = data.daysInMonth();

            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
            const workHours: number | false = await RendicontazioniService.getDatoreWorkHours(personId, year, month)
                .then((data: {
                    workhours: number;
                }) => {
                    return data.workhours;
                }).catch(() => {
                    return false;
                });

            const permessiStudioEsami = RendicontazioniService.getPersonPermessiStudioEsami(personId);
            const dettaglioRendicontazione = RendicontazioniService.getRendicontazione(personId, year, month);
            
            // dettaglio rendicontazione + permessi studio/esami
            await Promise.all([dettaglioRendicontazione, permessiStudioEsami])
            .then((data: any) => {
                    let dettaglio = { ...data[0], permessiStudioEsami: { ...data[1] } }

                    this.setState({
                        year,
                        month,
                        giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                        giorniSettimana: [...giorni_2, ...giorni_1],
                        giorniMese: Array.from(Array(daysInMonth).keys()),
                        oreMese: workHours !== false ? workHours : giorniLavorativi(
                            year,
                            month,
                            dettaglio.firstday,
                            dettaglio.lastday
                        ) * 8
                    }, () => {
                        reportings.load(dettaglio, this);
                        EventBus.dispatch("hideLoader");
                    });
                },
                () => this.setState({
                    errorMessage: 'Mese non ancora rendicontato.',
                    year,
                    month,
                    giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                    giorniSettimana: [...giorni_2, ...giorni_1],
                    giorniMese: Array.from(Array(daysInMonth).keys()),
                    oreMese: workHours !== false ? workHours : giorniLavorativi(
                        year,
                        month,
                        0,
                        daysInMonth
                    ) * 8
                }, () => {
                    reportings.load({}, this);
                    EventBus.dispatch("hideLoader");
                })
            )
            .catch(() => this.setState({
                    errorMessage: 'Mese non ancora rendicontato.',
                    year,
                    month,
                    giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                    giorniSettimana: [...giorni_2, ...giorni_1],
                    giorniMese: Array.from(Array(daysInMonth).keys()),
                    oreMese: workHours !== false ? workHours : giorniLavorativi(
                        year,
                        month,
                        0,
                        daysInMonth
                    ) * 8
                }, () => {
                    EventBus.dispatch("hideLoader");
                })
            );

            $(".day").on('click', ".editParent", (event: JQuery.ClickEvent<HTMLElement, null, HTMLElement, HTMLElement>) => {
                const data: iRendicontazione = typeof event.currentTarget.dataset.edit !== 'undefined'
                    && JSON.parse(base64Decode(event.currentTarget.dataset.edit))
                const day = Number($(event.target).parents('.rendicontazione').attr('day'));
                const index = Number($(event.target).parents('.rendicontazione').attr('index'));
    
                reportings.setSelectedIndex(index);
    
                const ora_inizio = data.ora.inizio.split(':');
                const ora_fine = data.ora.fine.split(':');
                const viaggio = data.viaggio.tempo !== null
                    ? data.viaggio.tempo.split(':') : ['0', '00'];
                const pausa_id = typeof data.pausa.id === 'string' ? data.pausa.id : undefined;
                const pausa_text = typeof data.pausa.text?.trim() === 'string' ? data.pausa.text?.trim() : undefined;
    
                const formInitialValues: iReportingInitialFormData = {
                    tipologia: data.commessa.id !== null ? 'commessa' : 'causale',
                    commessa: data.commessa ? `${data.commessa.id}||${data.commessa.text?.trim()}||${data.commessa.code?.trim()}` : undefined,
                    causale: data.causale ? `${data.causale.id}||${data.causale.text?.trim()}` : undefined,
                    strumento: data.dispositivo.id && data.dispositivo.text?.trim()
                        ? `${data.dispositivo.id}||${data.dispositivo.text?.trim()}` : undefined,
                    operatore: data.operatore.id && data.operatore.text?.trim()
                        ? `${data.operatore.id}||${data.operatore.text?.trim()}` : undefined,
                    trasferta: data.trasferta.id && data.trasferta.text?.trim()
                        ? `${data.trasferta.id}||${data.trasferta.text?.trim()}` : undefined,
                    ora_fine_hh: ora_fine[0],
                    ora_fine_mm: ora_fine[1] === '30' ? '30' : '00',
                    ora_inizio_hh: ora_inizio[0],
                    ora_inizio_mm: ora_inizio[1] === '30' ? '30' : '00',
                    tempo_viaggio_hh: viaggio[0],
                    tempo_viaggio_mm: viaggio[1],
                    pausa: pausa_id && pausa_text ? `${pausa_id}||${pausa_text}`: "",
                    note: data.note,
                    rit_send: data.ritSend ? data.ritSend : false,
                    measures_sent: data.measuresSent ? data.measuresSent : false
                };
    
                this.setState({
                    formSubmit: reportings.editSubmitModal,
                    formInitialValues
                }, () => {
                    this.openModalRendicontazione(
                        this.state.year,
                        this.state.month,
                        day,
                        'edit'
                    )
                })
            });
        }
    }

    async downloadDocument(id: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento Documento in corso...' });
        const idDocumento = id;
        const documento: any = await DocumentiService.download(idDocumento);
        if (typeof documento.body !== 'undefined') {
            openBase64NewTab(documento);
        }
        EventBus.dispatch("hideLoader");
    }

    async modifyMonthRendicontazione(action: string) {
        EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });
        await reportings.updateRendicontazione(action);
        EventBus.dispatch("hideLoader");
    }

    async updateRendicontazione(status: string) {
        EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });
        await RendicontazioniService.amministrazioneUpdateRendicontazione(reportings.getRendicontazioneId(), { status });
        EventBus.dispatch("hideLoader");
        window.location.reload();
    }

    updateCommesseCallBack = async(value: string, formik: FormikProps<any>) => {
        const id: number = Number(value.split('|')[0]);

        const personId = parseInt(reportings.getPersonId());
        const person: any = await PersonService.getEmployee(personId);
        if (typeof person.error !== 'undefined') {
            Swal.fire(
                'errore generico',
                '',
                'error'
            );
        }

        const allJobOrders = person?.personjoborders.active.concat(person.personjoborders.expired);

        let jobOrder: any = undefined;
        
        allJobOrders?.forEach((value: PersonJoborder) => {
            return value.childs?.forEach((subValue) => {
                if(subValue.id === id) {
                    jobOrder = {
                        id: value.joborderid,
                        name: value.name,
                        code: value.code
                    }
                };
            })
        });

        if (jobOrder) {
            formik.setFieldValue('parent', `${jobOrder.id}||${jobOrder.name}||${jobOrder.code}`);
        } else {
            formik.setFieldValue('parent', undefined);
        }
    }

    openModalRendicontazione = async (
        year: number,
        month: number,
        day: number,
        type: string = "add",
        devices: IPlanningDevice[] | null = null
    ) => {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        reportings.setSelectedDay(year, month, day);
        
        const personId = parseInt(reportings.getPersonId());
        const person: any = await PersonService.getEmployee(personId);
        if (typeof person.error !== 'undefined') {
            Swal.fire(
                'errore generico',
                '',
                'error'
            );
        }

        const causaliAll = await CausaliService.getAllDatore().then((response: iCausale[]) => { return response } ).catch((reason) => { return null });
        const causali: iFormFieldOption[] = causaliAll
            ? causaliAll.map((item: iCausale) => { return { value: `${item.id}||${item.name.trim()}`, label: item.name.trim() } })
            : [];

        let strumenti: iFormFieldOption[] = [];
        const strumentiAll = await RendicontazioniService.magazzinoStrumenti().then((response: iStrumento[]) => { return response } ).catch((reason) => { return null });
        if (!devices) {
            strumenti = strumentiAll
                ? strumentiAll.map((item: iStrumento) => { return {
                    value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                    label: `${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`
                }})
                : [];
        } else {
            strumenti = devices.map((item: IPlanningDevice) => {
                return {
                    value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                    label: `${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`
                }
            })
        }

        const operatoriAll = await RendicontazioniService.getOperatori().then((response: iOperatore[]) => { return response } ).catch((reason) => { return null });
        const operatori: iFormFieldOption[] = operatoriAll
            ? operatoriAll.map((item: iOperatore) => { return { value: `${item.id}||${item.name.trim()}`, label: item.name.trim() } })
            : [];

        const trasferteAll = await RendicontazioniService.getTrasferte().then((response: iTrasferta[]) => { return response } ).catch((reason) => { return null });
        const trasferte: iFormFieldOption[] = trasferteAll
            ? trasferteAll.map((item: iTrasferta) => { return { value: `${item.id}||${item.name.trim()}`, label: item.name.trim() } })
            : [];

        const date = moment(year.toString() + '/' + month.toString() + '/' + day.toString());

        const jobordersActive = this.mapCommesse(person.personjoborders.active);
        let jobordersExpiredArray: JoborderObject[] = [];

        person.personjoborders.expired.length > 0 && person.personjoborders.expired.forEach((joborder: JoborderObject) => {
            const startDate = moment(joborder.startdate);
            const endDate = moment(joborder.enddate);
            if (date.isBetween(startDate, endDate, 'days', "[]")) {
                jobordersExpiredArray.push(joborder);
            }
        });

        let jobordersExpired = this.mapCommesse(jobordersExpiredArray);

        const jobordersAll = jobordersActive.concat(jobordersExpired);
        const joborders: iFormFieldOption[] = jobordersAll.map((item: iCommesse) => { return {
            value: `${item.id}||${item.code.trim()} | ${item.value.trim()}||${item.code.trim()}`,
            label: item.value.trim()
        }});

        const days = $('#rendicontazione .day.selected').length;

        let modalTitle = 'Rendicontazione (';
        if (days === 0) {
            modalTitle += ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
        } else if (days === 1) {
            modalTitle += days + ' giorno';
        } else {
            modalTitle += days + ' giorni';
        }
        modalTitle += ')'

        this.setState({
            formFields: getModalRendicontazioneFields(
                joborders,
                causali,
                strumenti,
                operatori,
                trasferte,
                type,
                this.updateCommesseCallBack
            ),
        }, () => {
            this.setState({
                showModal: true,
                modalTitle,
                modalType: type,
            });
        });

        EventBus.dispatch("hideLoader");
    }

    closeModal() {
        this.setState({
            showModal: false,
            formInitialValues: this.initialValues
        });
    }

    render() {
        const { giorniSettimana, giorniMese, giorniVuoti, year, month, oreMese, showModal,
            modalTitle, modalType, formFields, formInitialValues, errorMessage, formSubmit
        } = this.state;
        const statoColor: any = {
            'O': { color: 'bg-cyan', text: 'Aperto' },
            'C': { color: 'bg-azure', text: 'Confermato' },
            'A': { color: 'bg-green', text: 'Accettato' },
            'R': { color: 'bg-red', text: 'Rifiutato' }
        };
        const permessiStudioEsami = reportings.getPersonPermessiStudioEsami()
        const personName = reportings.getPersonName()
        const personCompanyEmail = reportings.getPersonCompanyEmail()
        const personPersonalEmail = reportings.getPersonPersonalEmail()
        const personPhone = reportings.getPersonPhone()
        const personFirstDay = reportings.getFirstDay()
        const personLastDay = reportings.getLastDay()

        return <React.Fragment>
            <div className="card" id="rendicontazione">
                {
                    personName && <div className='card-body d-flex flex-column flex-lg-row align-items-center justify-content-start'>
                        <h3 className="col-12 d-flex align-items-center justify-content-start p-2 col-lg-3 m-0 px-lg-2">
                            {
                                reportings.getPersonStatus() === 'E' || reportings.getPersonStatus() === 'N' ?
                                    <div style={{ width: '16px', height: '16px', backgroundColor: statusColors[3].color }} className="rounded-circle"></div>
                                    : (
                                        reportings.getPersonStatus() === 'S' ?
                                            <div style={{ width: '16px', height: '16px', backgroundColor: statusColors[1].color }} className="rounded-circle"></div>
                                            :
                                            <div style={{ width: '16px', height: '16px', backgroundColor: statusColors[2].color }} className="rounded-circle"></div>
                                    )
                            }
                            <span className="ms-2">{reportings.getPersonName()}</span>
                        </h3>
                        {
                            personPersonalEmail && <CopyToClipboard text={personPersonalEmail}
                                onCopy={() => this.setState({ copied: true }, () => toast.success('Email personale copiata!', { className: "bg-dark" }))}>
                                <div className="col-12 col-lg-3 d-flex justify-content-start align-items-center px-2">
                                    Email personale <button style={resetButtonsStyle} className='nav-link m-0'>{personPersonalEmail}</button>
                                </div>
                            </CopyToClipboard>
                        }
                        {
                            personCompanyEmail && <CopyToClipboard text={personCompanyEmail}
                                onCopy={() => this.setState({ copied: true }, () => toast.success('Email aziendale copiata!', { className: "bg-dark" }))}>
                                <div className="col-12 col-lg-3 d-flex justify-content-start align-items-center px-2">
                                    Email aziendale <button style={resetButtonsStyle} className='nav-link m-0'>{personCompanyEmail}</button>
                                </div>
                            </CopyToClipboard>
                        }
                        {
                            personPhone && <CopyToClipboard text={personPhone}
                                onCopy={() => this.setState({ copied: true }, () => toast.success('Numero copiato!', { className: "bg-dark" }))}>
                                <div className="col-12 col-lg-3 d-flex justify-content-start align-items-center px-2">
                                    Numero di telefono <button style={resetButtonsStyle} className='nav-link m-0'>{personPhone}</button>
                                </div>
                            </CopyToClipboard>
                        }
                    </div>
                }
                {
                    permessiStudioEsami && <div className='card-body d-flex flex-column flex-lg-row align-items-center justify-content-start'>
                        <div className="col-12 col-lg-6 d-flex justify-content-start align-items-center p-2">
                            <strong>Permessi esami degli ultimi 3 anni</strong> <h3 className={"mb-0 ms-2 " + (permessiStudioEsami.overesame ? 'text-danger' : 'text-success')}>{permessiStudioEsami.oreesame} / {permessiStudioEsami.limiteesame}:00 ore</h3>
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-start align-items-center p-2">
                            <strong>Permessi studio dell'ultimo anno</strong> <h3 className={"mb-0 ms-2 " + (permessiStudioEsami.overstudio ? 'text-danger' : 'text-success')}>{permessiStudioEsami.orestudio} / {permessiStudioEsami.limitestudio}:00 ore</h3>
                        </div>
                    </div>
                }
                <ModalForm
                    showModal={showModal}
                    title={modalTitle}
                    modalType={modalType}
                    formFields={formFields}
                    initialValues={formInitialValues}
                    apiSubmit={() => {}}
                    submitCallback={formSubmit}
                    closeCallback={this.closeModal.bind(this)}
                />
                {errorMessage && <div className="d-flex align-items-center justify-content-center bg-danger p-2"><h3 className="m-0 me-2">Attenzione!</h3> {errorMessage}</div>}
                <div className="card-body">
                    <div className="card-title d-flex align-items-center justify-content-between mb-3 px-2">
                        <h2 className="mb-0 d-flex">
                            Rendicontazione - {getNomeMese(month - 1)} {year}
                            {
                                personLastDay !== 0 && personFirstDay !== 0 && <p className="mb-0 ms-2">(dal {personFirstDay}/{month} al {personLastDay}/{month})</p>
                            }
                            {
                                personLastDay !== 0 && personFirstDay === 0 && <p className="mb-0 ms-2">(dal 1/{month} al {personLastDay}/{month})</p>
                            }
                            {
                                personLastDay === 0 && personFirstDay !== 0 && <p className="mb-0 ms-2">(dal {personFirstDay}/{month} all'ultimo del mese)</p>
                            }
                        </h2>
                        <span className={"badge " + statoColor[reportings.getStatoCode()].color}>{statoColor[reportings.getStatoCode()].text}</span>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <header>
                                    <div className="row d-sm-flex text-white days-week">
                                        {giorniSettimana.map((giorno: string) => {
                                            return <h5 key={'g' + giorno} className="col-sm p-1 text-center">{giorno}</h5>
                                        })}
                                    </div>
                                </header>
                                <div className="row border border-right-0 border-bottom-0 newbg">
                                    {giorniMese.map((index: string) => {
                                        const giorno = Number(index + 1);
                                        const festivo = checkFestivo(year, month, giorno);
                                        const classNameContainer = ["position-relative", "d" + giorno, "day col-sm p-2 border border-left-0 border-top-0", (festivo ? 'festivo' : '')];
                                        const classNameDay = ["day-number col-2", (festivo ? 'festivo' : '')];
                                        return <React.Fragment key={"d" + giorno}><div id={"d" + giorno} className={classNameContainer.join(" ")}>
                                            <span id={"totale-giorno-" + giorno} className="totale-ore-giorno"></span>
                                            <h5 className="row align-items-center">
                                                {['C', 'A'].includes(reportings.getStatoCode()) &&
                                                    <div onClick={() => {
                                                        this.setState({formSubmit: reportings.addSubmitModal.bind(this)}, () => {
                                                            this.openModalRendicontazione(year, month, giorno)
                                                        });
                                                    }}>
                                                        <i style={{ fontSize: "18px" }} className="fa fa-plus-circle icon text-primary" aria-hidden="true"></i>
                                                    </div>
                                                }
                                                <span className={classNameDay.join(" ")}>{giorno}</span>
                                                <small className="col d-sm-none text-center text-muted">{getGiornoSettimana(year, month, giorno)}</small>
                                                <span className="col-1"></span>
                                            </h5>
                                            <div id={"giorno-" + giorno}></div>
                                        </div>
                                            {giorno % 7 === 0 ? <div className="w-100"></div> : ''}
                                        </React.Fragment>
                                        //return <div>{giorno+1}</div>
                                    })}
                                    {giorniVuoti.map((index: string) => {
                                        return <div key={"subD" + index} className="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                                            <h5 className="row align-items-center">
                                                <span className="date col-1"></span>
                                                <small className="col d-sm-none text-center text-muted"></small>
                                                <span className="col-1"></span>
                                            </h5>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="mt-4 card-body">
                                <label>Documenti Allegati:</label>
                                <div>
                                    {reportings.getAttachements()?.length > 0 ?
                                        reportings.getAttachements().map((item: any, index: number) => {
                                            return <div key={index}>
                                                {item.path}
                                                <div onClick={() => this.downloadDocument(item.id)}>
                                                    <i style={iconsStyle} className="fa fa-download" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        })
                                        : <div>nessuno</div>
                                    }
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title totale-ore-title">Totale ore <span id="totale-ore">00:00</span> / <span className="oreMese">{oreMese}:00</span></h3>
                                    </div>
                                    <div className="card-body p-0 mt-0">
                                        <div>
                                            <table id="rendicontazione-totale-ore" className="table nodefault">
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 offset-md-2 mt-4">
                            {['C', 'A'].includes(reportings.getStatoCode()) &&
                                <div className="row justify-content-end px-2">
                                    <div className="col-md-4">
                                        <button value="R" className="btn btn-block btn-warning" onClick={() => this.updateRendicontazione('R')}>Rifiuta Mese</button>
                                    </div>
                                    {reportings.getStatoCode() === 'C' &&
                                        <div className="col-md-4">
                                            <button value="A" className="btn btn-block btn-success" onClick={() => this.updateRendicontazione('A')}>Accetta Mese</button>
                                        </div>
                                    }
                                    <div className="col-md-4">
                                        <button className="btn btn-block btn-danger" onClick={() => this.modifyMonthRendicontazione('M')}>Modifica Mese</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                this.state.copied ? <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                    theme="dark"
                /> : null
            }
        </React.Fragment>
    }
}