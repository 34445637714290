/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { IPlanningEventData, IPlanningResource } from '../../helpers/interfaces/planning';
import TableBootstrap, { TableBSColumn } from '../../common/TableBootstrap';
import { planningPerDateUtenteFormFields } from '../../config/formFields/planning';
import { getMesiAbbreviati, getNomeMese } from '../../helpers/calendar';
import { Tab, TabProp } from '../../common/TabComponent';
import { IPlanningMonthType } from './planning';
import SearchBar from '../../common/SearchBar'
import $ from 'jquery';
import moment from "moment";
import 'moment/locale/it';
import './planning.css';
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { iFormField } from '../../helpers/interfaces/generic';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { RefObject } from '@fullcalendar/core';

type Props<T = any> = {
    getMonth: (year: number, month: number, type: IPlanningMonthType) => Promise<IPlanningMonth<T>>;
    getInterval: (start: string, end: string, type: IPlanningMonthType) => Promise<IPlanningMonth<T>>;
    viewHandler: (id: number) => Promise<void>;
    type: 'planning' | 'dipendente';
};

type State = {
    year: number,
    month: number,
    planningData: IPlanningEventData[] | null,
    planningColumns: TableBSColumn<IPlanningEventData>[][],
    dropdownYears: boolean,
    tabIndex: number,
    inputText: string,
    dateMaskFormInitialValues: {
        startdate: string,
        enddate: string,
    },
    loading: boolean
}

export type IPlanningMonth<T = any> = {
    planningData: T[] | null,
    planningColumns: TableBSColumn<T>[][]
}

export default class PlanningMonth extends Component<Props, State> {
    isAdministrator: boolean = true;
    clearBox: boolean = true;
    datesFormInitialValues = {
        startdate: '',
        enddate: ''
    }
    planningByMonth: boolean = true;
    dateMaskFormFields = planningPerDateUtenteFormFields();
    innerRefs: { [key: string]: string };
    scrollableContentRef: React.RefObject<any>;
    formRef: RefObject<HTMLFormElement> = createRef();

    constructor(props: Props) {
        super(props);

        const date = new Date();

        this.state = {
            year: date.getFullYear(),
            month: date.getMonth(),
            planningData: null,
            planningColumns: [],
            dropdownYears: false,
            tabIndex: 0,
            inputText: '',
            dateMaskFormInitialValues: { ...this.datesFormInitialValues },
            loading: false
        }

        this.isAdministrator = this.props.type === 'planning';
        this.innerRefs = {};
        this.setValidations = this.setValidations.bind(this)

        this.scrollableContentRef = React.createRef();
    }

    async componentDidMount() {
        await this.getMonth(
            this.state.year,
            this.state.month
        );

        this.updatePosition.bind(this)();

        document.body.addEventListener('scroll', this.updatePosition.bind(this));

        $('#planning_month').on('click', '.view_btn', async (e: JQuery.ClickEvent) => {
            e.preventDefault();
            const id = $(e.currentTarget).data('id');
            await this.props.viewHandler(id);
        });

        $('#planning-month-people-wrapper .wrapper').width($("#table-planning-month-people").width() ?? 0);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updatePosition.bind(this));
        window.removeEventListener('resize', this.updatePosition.bind(this));
    }

    componentDidUpdate(prevProps: Readonly<Props<any>>, prevState: Readonly<State>, snapshot?: any): void {
        this.getType().then((type) => {
            $(`#planning-month-${type}-wrapper .wrapper`).width($(`#table-planning-month-${type}`).width() ?? 0);
        });
    }

    updatePosition() {
        const scrollableContent = document.querySelector('.tab-content');
        const targetPosition = 2; // Posizione di scroll desiderata

        if (scrollableContent) {
            const rect = scrollableContent.getBoundingClientRect();
            // const scrollY = window.scrollY || window.pageYOffset;
            const scrollY = document.body.scrollTop;

            if (rect.top <= targetPosition) {
                document.body.scrollTo({
                    top: scrollY + rect.top
                });
            }
        }
    };

    SortArray(x: any, y: any) {
        return x.fullname.localeCompare(y.fullname);
    }

    async getType() {
        const { tabIndex } = this.state;

        let type: IPlanningMonthType = 'people';

        if (tabIndex === 1) {
            type = 'instruments'
        } else if (tabIndex === 2) {
            type = 'vehicles'
        } else if (tabIndex === 3) {
            type = 'licenses'
        }

        return type;
    }

    async getMonth(
        year: number,
        month: number
    ) {
        this.planningByMonth = true;
        const type = await this.getType();
        const monthData = await this.props.getMonth(
            year,
            month,
            type
        );

        if (this.props.type === 'planning') {
            // ordinamento alfabetico dell'elenco del personale
            if (monthData && monthData.planningData && monthData.planningData.length > 0) {
                const _elencoPersonale: IPlanningResource[] = [];
                monthData.planningData.map((item: any) => _elencoPersonale.push(item.resource));

                const elencoPersonale = _elencoPersonale.sort(this.SortArray);

                const newPlanningData: any = [];
                elencoPersonale.map((person: any) => {
                    monthData.planningData?.map((item: any) => {
                        if (item.resource.id === person.id) {
                            newPlanningData.push(item)
                        }
                        return newPlanningData
                    })
                    return newPlanningData
                })

                monthData.planningData = newPlanningData
            }
        }

        this.formRef.current?.reset();

        this.setState({
            planningColumns: monthData.planningColumns,
            planningData: monthData.planningData ?? [],
            year,
            month,
            dateMaskFormInitialValues: this.datesFormInitialValues
        })
    }

    async getInterval(
        start: string,
        end: string
    ) {
        this.planningByMonth = false;
        const { tabIndex } = this.state;

        let type: IPlanningMonthType = 'people';
        if (tabIndex === 1) {
            type = 'instruments'
        } else if (tabIndex === 2) {
            type = 'vehicles'
        } else if (tabIndex === 3) {
            type = 'licenses'
        }

        const intervalData = await this.props.getInterval(
            start,
            end,
            type
        );

        if (this.props.type === 'planning') {
            // ordinamento alfabetico dell'elenco del personale
            if (intervalData && intervalData.planningData && intervalData.planningData.length > 0) {
                const _elencoPersonale: IPlanningResource[] = [];
                intervalData.planningData.map((item: any) => _elencoPersonale.push(item.resource));

                const elencoPersonale = _elencoPersonale.sort(this.SortArray);

                const newPlanningData: any = [];
                elencoPersonale.map((person: any) => {
                    intervalData.planningData?.map((item: any) => {
                        if (item.resource.id === person.id) {
                            newPlanningData.push(item)
                        }
                        return newPlanningData
                    })
                    return newPlanningData
                })

                intervalData.planningData = newPlanningData
            }
        }

        const _startdate = start.split("-");
        const _year = Number(_startdate[0]);
        const _month = Number(_startdate[1]);

        this.setState({
            planningColumns: intervalData.planningColumns,
            planningData: intervalData.planningData ?? [],
            year: _year,
            month: _month
        })
    }

    async previousMonth() {
        const { month, year } = this.state

        let newMonth: number = month - 1;
        let newYear: number = year;

        if (newMonth < 0) {
            newMonth = 11;
            newYear = year - 1;
        }

        this.getMonth(newYear, newMonth);
    }

    async nextMonth() {
        const { month, year } = this.state;

        let newMonth: number = month + 1;
        let newYear: number = year;

        if (newMonth > 11) {
            newMonth = 0;
            newYear = year + 1;
        }

        this.getMonth(newYear, newMonth);
    }

    openCloseYears = () => this.setState({ dropdownYears: !this.state.dropdownYears });

    setInputText(data: string) {
        this.setState({ inputText: data });
        this.clearBox = false;
    }

    handleUpdate(formValue: any) {
        const s = new Date(formValue.startdate);
        const e = new Date(formValue.enddate);
        const start = s.setDate(s.getDate());
        const max = s.setDate(s.getDate() + 30);
        const end = e.setDate(e.getDate());

        if (start > end) {
            Swal.fire({
                title: '',
                text: 'La data di fine intervallo deve essere maggiore di quella di inizio.',
                icon: 'warning'
            })
        } else if (end <= max) {
            this.getInterval(formValue.startdate, formValue.enddate);
        } else {
            Swal.fire({
                title: '',
                text: 'Il massimo intervallo selezionabile è di 31 giorni.',
                icon: 'warning'
            })
        }
    }

    setValidations() {
        let validations: any = {};
        this.dateMaskFormFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    render() {
        const { year, month, planningColumns, planningData, inputText, loading } = this.state;

        const tabs: TabProp[] = [
            {
                id: 'people',
                label: 'Dipendenti',
                content: <div className='d-contents'>
                    <div id="planning-month-people-wrapper" className="wrapper-container" onScroll={() => {
                        $("#planning-month-people-container")
                            .scrollLeft($("#planning-month-people-wrapper").scrollLeft() ?? 0);
                    }}>
                        <div className="wrapper"></div>
                    </div>
                    <div id="planning-month-people-container" className='scrollable-table' ref={this.scrollableContentRef} onScroll={() => {
                        $("#planning-month-people-wrapper")
                            .scrollLeft($("#planning-month-people-container").scrollLeft() ?? 0);
                    }}>
                        {/* <SearchBar
                            parentCallback={this.setInputText.bind(this)}
                        /> */}
                        <TableBootstrap
                            id="table-planning-month-people"
                            className="table-planning-month"
                            inputText={inputText}
                            columnRows={planningColumns}
                            data={planningData ?? []}
                        />
                    </div>
                </div>
            },
            {
                id: 'instruments',
                label: 'Strumenti',
                content: <div className='d-contents'>
                    <div id="planning-month-instruments-wrapper" className="wrapper-container" onScroll={() => {
                        $("#planning-month-instruments-container")
                            .scrollLeft($("#planning-month-instruments-wrapper").scrollLeft() ?? 0);
                    }}>
                        <div className="wrapper"></div>
                    </div>
                    <div id="planning-month-instruments-container" className='scrollable-table' ref={this.scrollableContentRef} onScroll={() => {
                        $("#planning-month-instruments-wrapper")
                            .scrollLeft($("#planning-month-instruments-container").scrollLeft() ?? 0);
                    }}>
                        <TableBootstrap
                            id="table-planning-month-instruments"
                            className="table-planning-month"
                            inputText={inputText}
                            columnRows={planningColumns}
                            data={planningData ?? []} />
                    </div>
                </div>
            },
            {
                id: 'vehicles',
                label: 'Veicoli',
                content: <div className='d-contents'>
                    <div id="planning-month-vehicles-wrapper" className="wrapper-container" onScroll={() => {
                        $("#planning-month-vehicles-container")
                            .scrollLeft($("#planning-month-vehicles-wrapper").scrollLeft() ?? 0);
                    }}>
                        <div className="wrapper"></div>
                    </div>
                    <div id="planning-month-vehicles-container" className='scrollable-table' ref={this.scrollableContentRef} onScroll={() => {
                        $("#planning-month-vehicles-wrapper")
                            .scrollLeft($("#planning-month-vehicles-container").scrollLeft() ?? 0);
                    }}>
                        <TableBootstrap
                            id="table-planning-month-vehicles"
                            className="table-planning-month"
                            inputText={inputText}
                            columnRows={planningColumns}
                            data={planningData ?? []} />
                    </div>
                </div>
            },
            {
                id: 'licenses',
                label: 'Licenze',
                content: <div className='d-contents'>
                    <div id="planning-month-licenses-wrapper" className="wrapper-container" onScroll={() => {
                        $("#planning-month-licenses-container")
                            .scrollLeft($("#planning-month-licenses-wrapper").scrollLeft() ?? 0);
                    }}>
                        <div className="wrapper"></div>
                    </div>
                    <div id="planning-month-licenses-container" className='scrollable-table' ref={this.scrollableContentRef} onScroll={() => {
                        $("#planning-month-licenses-wrapper")
                            .scrollLeft($("#planning-month-licenses-container").scrollLeft() ?? 0);
                    }}>
                        <TableBootstrap
                            id="table-planning-month-licenses"
                            className="table-planning-month"
                            inputText={inputText}
                            columnRows={planningColumns}
                            data={planningData ?? []} />
                    </div>
                </div>
            }
        ];

        const yearsClass = `dropdown-menu${this.state.dropdownYears ? " show" : ""}`;
        const years = [];

        for (let i = moment().year(); i >= 2016; i--) {
            years.push(i);
        }

        return <div id="planning-container">
            <div className="row d-flex justify-content-between align-items-center mb-3">
                {this.isAdministrator && <div className='mt-2 mb-3'>&#x2022; Seleziona un mese</div>}
                <div className="col-12 col-sm-10 d-flex justify-content-between align-items-center">
                    <button
                        className="btn btn-outline-primary me-3"
                        disabled={!this.planningByMonth}
                        title="Mese precedente"
                        onClick={() => this.previousMonth()}
                    >
                        <i style={{ fontSize: "18px" }} className="fa fa-angle-left" aria-hidden="true" />
                    </button>
                    <div className="d-flex flex-column flex-lg-row align-items-center">
                        <div id="yearsDropdown" className="nav-item dropdown" onClick={this.openCloseYears}>
                            <button className="d-flex btn-link border0" type="button" id="dropdownYears" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <h2 className={'mb-0 ' + (this.planningByMonth ? '' : 'color-white')}>{getNomeMese(month)} {year}</h2>
                            </button>
                            <div className={yearsClass} aria-labelledby="dropdownYears">
                                {
                                    years.map((value: number, index: number) => {
                                        return <span
                                            key={index}
                                            className={"dropdown-item" + (year === value ? " active" : "")}
                                            onClick={() => { value !== year && this.getMonth(value, month); }}
                                        >
                                            {getNomeMese(month)} {value}
                                        </span>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <button
                        className="btn btn-outline-primary ms-3"
                        disabled={!this.planningByMonth}
                        title="Mese successivo"
                        onClick={() => this.nextMonth()}
                    >
                        <i style={{ fontSize: "18px" }} className="fa fa-angle-right" aria-hidden="true" />
                    </button>
                </div>
                <div className="col-12 col-sm-2 d-block text-end">
                    <a
                        href="#current-month"
                        className="text-outline-primary fw-bold"
                        onClick={(e) => {
                            e.preventDefault();
                            const date = new Date();
                            // this.getMonth(year, date.getMonth());
                            this.getMonth(date.getFullYear(), date.getMonth());
                        }}
                    >
                        Vai al mese corrente
                    </a>
                </div>
            </div>
            <div id="select_month" className="row my-3">
                <div className="col-md-12">
                    <div className="container-fluid">
                        <div className="row">
                            {getMesiAbbreviati().map(
                                (mese: string, index: number) => {
                                    return <div key={index} className="col-12 col-sm-3 col-md-2 col-lg-1 text-center py-1">
                                        <button
                                            className={'btn radius12 w-100 ' + (this.planningByMonth && month === index ? ' btn-primary' : 'btn-outline-primary')}
                                            onClick={() => this.getMonth(year, index)}
                                        >
                                            {mese}
                                        </button>
                                    </div>
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {this.isAdministrator && <div className='mt-5'>&#x2022; Seleziona un intervallo di tempo</div>}
            {this.isAdministrator && <div className='mt-3 mb-5'>
                <Formik
                    enableReinitialize
                    initialValues={this.state.dateMaskFormInitialValues}
                    validationSchema={this.setValidations.bind(this)}
                    onSubmit={this.handleUpdate.bind(this)}
                >
                    {(formik: FormikProps<any>) => {
                        return <Form ref={this.formRef} className="px-3 pt-3">
                            {this.dateMaskFormFields && this.dateMaskFormFields.map((item: iFormField, key: number) => {
                                return <div className="form-group mb-3 row" key={key}>
                                    <label className="form-label col-3 col-form-label">{item.label}</label>
                                    <div className="col">
                                        <Field
                                            innerRef={(el: any) => this.innerRefs[item.name] = el}
                                            name={item.name}
                                            type={item.type}
                                            className={item.class}
                                            value={formik.values[item.name]}
                                        />
                                    </div>
                                    <ErrorMessage
                                        name={item.name}
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                            })}
                            <div className="d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm me-1"></span>
                                    )}
                                    <span>Carica il planning</span>
                                </button>
                            </div>
                        </Form>
                    }}
                </Formik>
            </div>
            }

            {this.props.type !== 'dipendente' ? <div id="planning_month">
                <SearchBar
                    parentCallback={this.setInputText.bind(this)}
                    clearBox={this.clearBox}
                />
                <Tab
                    id={'planning_month'}
                    tabs={tabs}
                    tabClickHandler={async (currentTab: number, args: TabProp['args']) => {
                        this.setState({ tabIndex: currentTab }, async () => {
                            await this.getMonth(year, month);
                        });

                        this.setInputText('');
                        this.clearBox = true;
                    }}
                />
            </div> : <TableBootstrap
                id="planning_month"
                columnRows={planningColumns}
                data={planningData ?? []} />
            }
        </div>
    }
}
