import { Component } from "react";

type Props = {
    parentCallback: any,
    clearBox: boolean
};

let inputValue = "";

export default class SearchBar extends Component<Props> {
 
    handleChange = (e: any) => {
        e.preventDefault();

        inputValue = e.target.value;

        this.props.parentCallback(
            e.target.value.toLowerCase()
        );
    };

    render() {
        return <div>

            <div className='custom-container mt-4 mb-4'>
                <div className="search-text">Search: </div>
                <input
                    type="search"
                    placeholder=""
                    value={this.props.clearBox ? '' : inputValue}
                    onChange={this.handleChange}
                    className="search-bar"
                />
                {/* <button className="btn fa fa-search"></button> */}
            </div>

        </div>
    }
};