import React, { Component } from "react";
import EventBus from "../../../common/EventBus";
import ModalForm from '../../../common/ModalForm';

import AttivitaService from "../../../services/api/database/attivita.service";
import { listAttivitaColumns, getListAttivitaColumnDefs } from "../../../config/tableColumns/attivita";
import { getEditAttivitaFormFields, getAddAttivitaFormFields } from "../../../config/formFields/attivita";
import Table from '../../../common/TableComponent';
import { iFormField } from "../../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {
    history: any
};
type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    attivita: any,
    formInitialValues: { [key: string]: any }
}
export default class Attivita extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            attivita: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;// LicenzeService.personAdd;
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const attivita = await AttivitaService.getAll();
        if (attivita.length > 0) {
            this.setState({ attivita });
        }
        EventBus.dispatch("hideLoader");

        $('#table_attivita.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento attivita in corso...' });
            const idAttivita = $(e.currentTarget).data('id');
            const attivita = await AttivitaService.get(idAttivita);
            if (attivita) {
                this.setState({ formFields: getEditAttivitaFormFields(), formInitialValues: attivita }, () => {
                    this.apiSubmit = AttivitaService.put;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    openAddModale() {
        this.apiSubmit = AttivitaService.add;
        this.setState({ formFields: getAddAttivitaFormFields(), formInitialValues: {}, showModal: true, modalType: 'add' });
    }

    render() {
        const { formFields, showModal, modalType, attivita, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h2 className="card-title">Attivita</h2>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Aggiungi attivita</span>
                            </button>
                        </div>
                        <ModalForm showModal={showModal} title={'Attivita'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {attivita.length > 0 && <Table id="table_attivita" columns={listAttivitaColumns} columnDefs={getListAttivitaColumnDefs()} datas={attivita} buttons={this.buttons} />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}