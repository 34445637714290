import { AxiosResponse } from 'axios';
import axios from '../../api';
import { IMagazzinoStrumento } from '../../../helpers/interfaces/magazzino/magazzino-strumenti';

const API_URL = process.env.REACT_APP_API_URL;

class MagazzinoStrumentiService {

    //* se ci sono chiamate che restituiscono 200 ma non hanno dati, metti controllo su error

    getAll(): Promise<IMagazzinoStrumento[]> {
        return axios.get<IMagazzinoStrumento[]>(API_URL + 'magazzino/strumenti')
            .then((response: AxiosResponse<IMagazzinoStrumento[]>) => { return Array.isArray(response.data) ? response.data : []; })
            .catch((error) => { return Promise.reject(); });
    }

    getOne(id: string): Promise<IMagazzinoStrumento> {
        return axios.get<IMagazzinoStrumento>(API_URL + 'magazzino/strumenti/' + id)
            .then((response: AxiosResponse<IMagazzinoStrumento>) => { return response.data })
            .catch((error) => { return Promise.reject(); });
    }

    add(body: FormData): Promise<IMagazzinoStrumento> {
        return axios.post<FormData, AxiosResponse<IMagazzinoStrumento>>(API_URL + 'magazzino/strumenti', body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<IMagazzinoStrumento>) => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

    edit(id: string, body: FormData): Promise<IMagazzinoStrumento> {
        return axios.put<FormData, AxiosResponse<IMagazzinoStrumento>>(API_URL + 'magazzino/strumenti/' + id, body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<IMagazzinoStrumento>) => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

}

export default new MagazzinoStrumentiService();