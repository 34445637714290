import axios from '../api';
import { AxiosResponse } from 'axios';
import { ReportType } from "../../helpers/interfaces/user";

const API_URL = process.env.REACT_APP_API_URL;

class ReportsService {
  daily(data: any) {
    return axios.post(API_URL + 'datore/report/giorno', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  monthly(data: any) {
    return axios.post(API_URL + 'datore/report/mese', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  presences_monthly(data: any) {
    return axios.post(API_URL + '/datore/report/presenze', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  yearly(anno: any, personId: number) {
    return axios.get(API_URL + 'datore/report/anno/' + anno + '/' + personId)
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  joborder(year: number, month: number, joborder: number) {
    let url = 'datore/report/commesse/anno/' + year;
    if (month) { url += '/mese/' + month; }
    if (joborder) { url += '/' + joborder; }

    return axios.get(API_URL + url)
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  material(stato: any) {
    let url = 'datore/report/dispositivi';
    if (stato) { url += '/' + stato; }

    return axios.get(API_URL + url)
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  registry(stato: any,) {
    let url = 'datore/report/anagrafica';
    if (stato) { url += '/' + stato; }

    return axios.get(API_URL + url)
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  ral() {
    return axios.get(API_URL + 'datore/report/ral')
      .then(response => response.data)
      .catch(error => null)
  }

  getTablenameList(): Promise<ReportType[]> {
    return axios.get(API_URL + 'datore/report/tablename')
      .then((response: AxiosResponse<ReportType[]>) => { return response.data; })
      .catch((error) => {return Promise.reject(error); });
  }

  byDates(data: any): Promise<any[]> {
    return axios.get(API_URL + 'datore/report/dates/' + data.startdate + '/' + data.enddate + '/' + Number(data.tipo))
      .then((response: AxiosResponse<any[]>) => { return response.data; })
      .catch((error) => {return Promise.reject(error); });
    }
}
export default new ReportsService();