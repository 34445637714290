import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listAttivitaColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Tipo", "data": "type" },
    { "title": "Codice", "data": "code" },
    { "title": "Descrizione", "data": "descrizione" }
];

export function getListAttivitaColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                return `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;
            },
        },
        {
            target: 1,
            //data: null,
            render: function (data: any, type: any, row: any) {
                return data === 'O' ? 'CONSUNTIVO' : 'CORPO';
            },
        },
    ];
}