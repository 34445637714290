import { Component } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { history } from '../helpers/history';
import EventBus from "../common/EventBus";
import CssLoader from "../common/cssloader/CssLoader";
import NavigationBar from "../components/NavigationBar";
import route from './routes.json';
import navBar from './navbar.json';
import Breadcrumb from '../components/Breadcrumb';
import authService from '../services/auth.service';
import IUser from '../types/user.type';
import '@tabler/core/dist/css/tabler.min.css'
import '@tabler/core/dist/js/tabler.min.js'
import "../App.css";

// import components
import Home from '../pages/home';
import Profile from '../pages/profile';
import Login from '../pages/login';
import NotFound from '../pages/notfound';
import ElencoPersonale from '../pages/organico/elenco-personale';
import DettaglioPersonale from '../pages/organico/dettaglio-personale';
import NuovoPersonale from '../pages/organico/nuovo-personale';
import Tutor from '../pages/organico/tutor';
import Attestati from '../pages/organico/attestati';
import Clienti from '../pages/contratti/clienti';
import Attivita from '../pages/database/contratti/attivita';
import TipologiaContratti from '../pages/database/contratti/tipologia-contratti';
import FineContratti from '../pages/database/contratti/fine-contratti';
import Commesse from '../pages/database/contratti/commesse';
import Contatti from '../pages/contratti/contatti';
import StatoRIT from '../pages/database/contratti/stato-rit';
import RitAmministratore from '../pages/contratti/rit-amministratore';
import RitEditAmministratore from '../pages/contratti/rit-edit-amministratore';
import EstablishmentsComponent from '../pages/contratti/stabilimenti';
import PermessiFerie from '../pages/organico/permessi-ferie';
import PermessiFerieComponent from '../pages/organico/dettaglio/PermessiFerieComponent/PermessiFerieComponent';
import CalendarioComponent from '../pages/organico/dettaglio/PermessiFerieComponent/CalendarioComponent';
import DaValutareComponent from '../pages/organico/dettaglio/PermessiFerieComponent/DaValutareComponent';
import ConfermateComponent from '../pages/organico/dettaglio/PermessiFerieComponent/ConfermateComponent';
import StatoRendicontazione from '../pages/organico/rendicontazione/stato-rendicontazione';
import ElencoRendicontazione from '../pages/organico/rendicontazione/elenco';
import DettaglioRendicontazione from '../pages/organico/rendicontazione/dettaglio';
import NoteSpeseAmministrazione from '../pages/organico/note-spese';
import NoteSpeseDettaglio from '../pages/organico/note-spese/note-spese-dettaglio';
import GestioneRateiComponent from '../pages/organico/gestione-ratei';
import DettaglioRateiComponent from '../pages/organico/dettaglio-ratei';
import Ruoli from '../pages/database/ruoli';
import TitoliStudio from '../pages/database/titoli-studio';
import Adempimenti from '../pages/database/adempimenti';
import Causali from '../pages/database/causali';
import Comuni from '../pages/database/comuni';
import BusinessUnit from '../pages/database/businessunit';
import ConfigurationComponent from '../pages/database/configuration';
import ExpensesComponent from '../pages/database/expenses';
import Reports from '../pages/reports';
import Scadenzario from '../pages/scadenzario/scadenzario';
import ElencoGruppo from '../pages/database/gruppi/elenco-gruppi';
import NuovoGruppo from '../pages/database/gruppi/nuovo-gruppo';
import HomeDipendente from '../pages/dipendenti/home-dipendente';
import RendicontazioneDipendente from '../pages/dipendenti/rendicontazione';
import PermessiFerieDipendente from '../pages/dipendenti/permessi-ferie';
import RitDipendente from '../pages/dipendenti/rit-dipendente';
import NoteSpese from '../pages/dipendenti/note-spese';
import NoteSpeseDipendenteDettaglio from '../pages/dipendenti/note-spese/note-spese-dettaglio';
import RateiComponent from '../pages/dipendenti/ratei';
import SignDocument from '../pages/signDocument';
import MagazzinoHome from '../pages/magazzino/magazzino-home';
import MagazzinoDispositivi from '../pages/magazzino/dispositivi/magazzino-dispositivi';
import MagazzinoDettaglioDispositivo from '../pages/magazzino/dispositivi/magazzino-dettaglio-dispositivo';
import MagazzinoNuovoDispositivo from '../pages/magazzino/dispositivi/magazzino-nuovo-dispositivo';
import MagazzinoContratti from '../pages/magazzino/contratti/magazzino-contratti';
import MagazzinoNuovoContratto from '../pages/magazzino/contratti/magazzino-nuovo-contratto';
import MagazzinoDettaglioContratto from '../pages/magazzino/contratti/magazzino-dettaglio-contratto';
import MagazzinoServizi from '../pages/magazzino/servizi/magazzino-servizi';
import MagazzinoNuovoServizio from '../pages/magazzino/servizi/magazzino-nuovo-servizio';
import MagazzinoDettaglioServizio from '../pages/magazzino/servizi/magazzino-dettaglio-servizio';
import MagazzinoLicenze from '../pages/magazzino/licenze/magazzino-licenze';
import MagazzinoNuovaLicenza from '../pages/magazzino/licenze/magazzino-nuova-licenza';
import MagazzinoDettaglioLicenza from '../pages/magazzino/licenze/magazzino-dettaglio-licenza';
import MagazzinoProdotti from '../pages/magazzino/prodotti/magazzino-prodotti';
import MagazzinoNuovoProdotto from '../pages/magazzino/prodotti/magazzino-nuovo-prodotto';
import MagazzinoDettaglioProdotto from '../pages/magazzino/prodotti/magazzino-dettaglio-prodotto';
import MagazzinoStatoDispositivi from '../pages/magazzino/stato-dispositivi/magazzino-stato-dispositivi';
import MagazzinoNuovoStato from '../pages/magazzino/stato-dispositivi/magazzino-nuovo-stato';
import MagazzinoDettaglioStato from '../pages/magazzino/stato-dispositivi/magazzino-dettaglio-stato';
import MagazzinoTipologiaDispositivi from '../pages/magazzino/tipologie-dispositivi/magazzino-tipologia-dispositivi';
import MagazzinoNuovaTipologia from '../pages/magazzino/tipologie-dispositivi/magazzino-nuova-tipologia';
import MagazzinoDettaglioTipologia from '../pages/magazzino/tipologie-dispositivi/magazzino-dettaglio-tipologia';
import MagazzinoVeicoli from '../pages/magazzino/veicoli/magazzino-veicoli';
import MagazzinoVeicoliManutenzione from '../pages/magazzino/veicoli/magazzino-veicoli-manutenzione';
import MagazzinoStrumenti from '../pages/magazzino/strumenti/magazzino-strumenti';
import MagazzinoDettaglioStrumento from '../pages/magazzino/strumenti/magazzino-dettaglio-strumento';
import MagazzinoNuovoStrumento from '../pages/magazzino/strumenti/magazzino-nuovo-strumento';
import MagazzinoStatoStrumenti from '../pages/magazzino/stato-strumenti/magazzino-stato-strumenti';
import MagazzinoDettaglioStatoStrumenti from '../pages/magazzino/stato-strumenti/magazzino-dettaglio-stato-strumenti';
import MagazzinoNuovoStatoStrumenti from '../pages/magazzino/stato-strumenti/magazzino-nuovo-stato-strumenti';
import MagazzinoTipologiaStrumenti from '../pages/magazzino/tipologia-strumenti/magazzino-tipologia-strumenti';
import MagazzinoDettaglioTipologiaStrumenti from '../pages/magazzino/tipologia-strumenti/magazzino-dettaglio-tipologia-strumenti';
import MagazzinoNuovaTipologiaStrumenti from '../pages/magazzino/tipologia-strumenti/magazzino-nuova-tipologia-strumenti';
import MagazzinoCategoriaStrumenti from '../pages/magazzino/categoria-strumenti/magazzino-categoria-strumenti';
import MagazzinoDettaglioCategoriaStrumenti from '../pages/magazzino/categoria-strumenti/magazzino-dettaglio-categoria-strumenti';
import MagazzinoNuovaCategoriaStrumenti from '../pages/magazzino/categoria-strumenti/magazzino-nuova-categoria-strumenti';
import ChangePassword from '../pages/change-password';
import HomeAmministrazione from '../pages/home-amministrazione/home-amministrazione';
import ElencoNomine from '../pages/nomine/elenco-nomine';
import InviaNomina from '../pages/nomine/invia-nomine';
import ElencoSollecitiNomine from '../pages/nomine/elenco-solleciti';
import ElencoHRDocuments from '../pages/HRDocuments/elenco-documenti';
import InviaHRDocouments from '../pages/HRDocuments/invia-documento';
import ElencoSollecitiHRDocuments from '../pages/HRDocuments/elenco-solleciti';
import HomeAdmin from '../pages/admin/home-admin';
import ElencoUtenti from '../pages/admin/elenco-utenti';
import ElencoTipiUtente from '../pages/admin/elenco-tipi-utente';
import ElencoRotte from '../pages/admin/elenco-rotte';
import SincronizzaRotte from '../pages/admin/sincronizza-rotte';
import ElencoAutorizzazioni from '../pages/admin/elenco-autorizzazioni';
import GestioneRuoliComponent from '../pages/admin/gestione-ruoli';
import Notifiche from '../pages/notifiche';
import Planning from '../pages/planning/planning';
import PlanningNotifiche from '../pages/planning/planning-notifiche';
import PlanningDipendente from '../pages/planning/planning-dipendente';
import OreSettimanali from '../pages/organico/ore-settimanali';

type Props = {};

type State = {
  showLoader: boolean,
  loaderText: string,
  currentUser: IUser | null
};

class Routes extends Component<Props, State> {
  // set data
  data: any = route;

  // add components
  components: any = {
    Home,
    HomeAmministrazione,
    HomeDipendente,
    Profile,
    Login,
    ChangePassword,
    NotFound,
    ElencoPersonale,
    DettaglioPersonale,
    PermessiFerieComponent,
    ConfermateComponent,
    DaValutareComponent,
    CalendarioComponent,
    NuovoPersonale,
    Tutor,
    Attestati,
    Ruoli,
    TitoliStudio,
    Adempimenti,
    Causali,
    Comuni,
    BusinessUnit,
    ConfigurationComponent,
    ExpensesComponent,
    Clienti,
    Attivita,
    TipologiaContratti,
    FineContratti,
    Commesse,
    Contatti,
    StatoRIT,
    RitAmministratore,
    RitEditAmministratore,
    EstablishmentsComponent,
    Reports,
    PermessiFerie,
    StatoRendicontazione,
    ElencoRendicontazione,
    DettaglioRendicontazione,
    NoteSpeseAmministrazione,
    NoteSpeseDettaglio,
    GestioneRateiComponent,
    DettaglioRateiComponent,
    ElencoGruppo,
    NuovoGruppo,
    Scadenzario,
    RendicontazioneDipendente,
    PermessiFerieDipendente,
    RitDipendente,
    NoteSpese,
    NoteSpeseDipendenteDettaglio,
    RateiComponent,
    SignDocument,
    MagazzinoHome,
    MagazzinoDispositivi,
    MagazzinoDettaglioDispositivo,
    MagazzinoNuovoDispositivo,
    MagazzinoContratti,
    MagazzinoNuovoContratto,
    MagazzinoDettaglioContratto,
    MagazzinoServizi,
    MagazzinoNuovoServizio,
    MagazzinoDettaglioServizio,
    MagazzinoLicenze,
    MagazzinoNuovaLicenza,
    MagazzinoDettaglioLicenza,
    MagazzinoProdotti,
    MagazzinoNuovoProdotto,
    MagazzinoDettaglioProdotto,
    MagazzinoStatoDispositivi,
    MagazzinoNuovoStato,
    MagazzinoDettaglioStato,
    MagazzinoTipologiaDispositivi,
    MagazzinoNuovaTipologia,
    MagazzinoDettaglioTipologia,
    MagazzinoVeicoli,
    MagazzinoVeicoliManutenzione,
    MagazzinoStrumenti,
    MagazzinoDettaglioStrumento,
    MagazzinoNuovoStrumento,
    MagazzinoStatoStrumenti,
    MagazzinoDettaglioStatoStrumenti,
    MagazzinoNuovoStatoStrumenti,
    MagazzinoTipologiaStrumenti,
    MagazzinoDettaglioTipologiaStrumenti,
    MagazzinoNuovaTipologiaStrumenti,
    MagazzinoCategoriaStrumenti,
    MagazzinoDettaglioCategoriaStrumenti,
    MagazzinoNuovaCategoriaStrumenti,
    ElencoNomine,
    InviaNomina,
    ElencoSollecitiNomine,
    ElencoHRDocuments,
    InviaHRDocouments,
    ElencoSollecitiHRDocuments,
    HomeAdmin,
    ElencoUtenti,
    ElencoTipiUtente,
    ElencoRotte,
    SincronizzaRotte,
    ElencoAutorizzazioni,
    GestioneRuoliComponent,
    Notifiche,
    Planning,
    PlanningNotifiche,
    PlanningDipendente,
    OreSettimanali,
    Redirect
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      showLoader: false,
      loaderText: '',
      currentUser: null
    }

    EventBus.on("showLoader", this.showLoader);

    navBar.forEach((item) => {
      item?.elements && item?.elements?.length > 0 && this.getNavbarElements(item.elements);

      this.data.push({
        component: "Redirect",
        name: item.name,
        path: item.path,
        protected: true,
        to: item.to,
      });
    });

    // Home Route
    this.data.push({
      component: "Home",
      path: "/",
      exact: false,
      protected: true,
      name: "home",
    });

    // Route not found
    this.data.push({
      component: "NotFound",
      path: "*",
      exact: false,
      protected: false,
      name: "not-found",
    });

    EventBus.on("hideLoader", this.hideLoader);
  }

  async componentDidMount(): Promise<void> {
    const currentUser = await authService.getCurrentUser()
    this.setState({currentUser});

    history.listen(async () => {
      const currentUser = await authService.getCurrentUser()
      this.setState({currentUser});

      if (currentUser) {
        const decodedToken = this.parseJwt(currentUser.token);

        if (decodedToken.exp * 1000 < Date.now()) {
          authService.logout();
        }
      }
    });
  }

  parseJwt = (token: string): any => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  getNavbarElements = (elements: any[]): void => {
    elements.forEach((item: any) => {
      item?.elements?.length > 0 && this.getNavbarElements(item.elements);

      if (item.isDropend) {
        this.data.push({
          component: "Redirect",
          name: item.name,
          path: item.path,
          protected: true,
          to: item.to,
        });
      }
    });
  }

  showLoader = (data: any): void => { this.setState({ showLoader: true, loaderText: data.detail.text }); }
  hideLoader = (): void => { this.setState({ showLoader: false }); }

  render(): JSX.Element {
    const { showLoader, currentUser, loaderText } = this.state;

    return <Router history={history}>
      {
        currentUser && <NavigationBar navBar={navBar} />
      }
      <div className="container-fluid py-3" style={{ minHeight: "calc(100% - 112px)" }}>
        <CssLoader active={showLoader} text={loaderText} />
        <Switch>
          {this.data.map((route: any, key: number) => (
            <PrivateRoute key={key} {...route} component={this.components[route.component]} />
          ))}
        </Switch>
      </div>
      {
        currentUser ? <footer className="navbar navbar-dark bg-primary">
          <div className='d-flex align-items-center mx-auto'>
            <span>Powered by</span>
            <a href="http://www.cgmconsulting.it" target="blank">
              <img src="/assets/CGM-logo-bianco.svg" alt="logo" height="40px" />
            </a>
          </div>
        </footer> : ''
      }
    </Router>
  }
}

const PrivateRoute = (
  { component: Component, ...rest }: any
) => (
  <Route
    {...rest}

    render={(props) => {
      const userString = localStorage.getItem("user");
      const currentUser = userString ? JSON.parse(userString) : null;

      if (rest.protected) {
        const routes = currentUser ? currentUser.routes : [];

        if (!currentUser) {
          return <Redirect to={'/login'} />;
        } else {
          if (rest.alias && !routes.includes(rest.alias))
            return <Redirect to={'/'} />;
        }
      }

      return <>
        {rest.protected && <Breadcrumb route={rest} />}
        {Component ? <Component {...props} routeParams={rest} to={rest.to ? rest.to : '/'} /> : <Home history={history} />}
      </>;
    }}
  />
);

export default Routes;