import { AxiosResponse } from 'axios';
import { Activity } from '../../../helpers/interfaces/joborders';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class AttivitaService {
  async getAll() {
    try {
      const response = await axios.get(API_URL + 'database/attivita');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getAllDatore(): Promise<Activity[]> {
    try {
      const response: AxiosResponse<Activity> = await axios.get(
        API_URL + 'datore/attivita'
      );

      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async get(id:any) {
    try {
      const response = await axios.get(API_URL + 'database/attivita/' + id);
      return typeof response.data == 'object' ? response.data : null;
    } catch (error) {
      return await Promise.reject();
    };
  }

  async put(id:any, data:any) {
    try {
      const response = await axios.put(API_URL + 'database/attivita/' + id, {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async add(data:any) {
    try {
      const response = await axios.post(API_URL + 'database/attivita', {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }
}

export default new AttivitaService();