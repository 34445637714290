/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Swal, { SweetAlertResult } from 'sweetalert2';
import EventBus from '../../common/EventBus';
import {
    IPlanningDetails,
    IPlanningEvent,
    IPlanningEventData,
    IPlanningActivity,
    IPlanningPeople,
    IPlanningDevice,
    IPlanningVehicle,
    IPLanningLicense
} from '../../helpers/interfaces/planning';
import { iFormField, iFormFieldOption } from '../../helpers/interfaces/generic';
import planningService from '../../services/api/planning.service';
import Modal from '../../common/Modal';
import ModalForm from '../../common/ModalForm';
import { TableBSColumn } from '../../common/TableBootstrap';
import PlanningMonth, { IPlanningMonth } from './planning-month';
import { fixDate, getGiorniSettimana, getGiornoSettimana, getGiornoSettimanaAbbreviato } from '../../helpers/calendar';
import PlanningService from './../../services/planning.service';
import moment from "moment";
import 'moment/locale/it';
import './planning.css';

type Props = {
    history: {
        push(url: string): void;
    }
};

type State = {
    showModal: boolean;
    showModalForm: boolean;
    planningDetails: IPlanningDetails | null;
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    jobOrders: iFormFieldOption[];
    activities: iFormFieldOption[];
    statuses: iFormFieldOption[];
    people: iFormFieldOption[];
    devices: iFormFieldOption[];
    vehicles: iFormFieldOption[];
    licenses: iFormFieldOption[];
    modalFormType: 'add' | 'edit' | 'change';
    modalFormTitle: string;
    formFields: iFormField[];
    formInitialValues: IPlanningEvent | {};
}

type IApiSubmit = ((id: number, data?: any) => Promise<any>) | ((data: any) => Promise<any>)

type IPlanningMonthDipendente = {plannings: (IPlanningEventData['plannings'])[], day: number}

export type IPlanningMonthType = 'people' | 'instruments' | 'vehicles' | 'licenses';

export default class PlanningDipendente extends Component<Props, State>
{
    apiSubmit: IApiSubmit = async() => {};

    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: false,
            showModalForm: false,
            startDate: null,
            endDate: null,
            jobOrders: [],
            activities: [],
            statuses: [],
            people: [],
            devices: [],
            vehicles: [],
            licenses: [],
            planningDetails: null,
            modalFormType: 'add',
            modalFormTitle: 'Crea un nuovo evento',
            formFields: [],
            formInitialValues: {},
        }
    }

    async viewHandler(idEvento: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        await planningService.getEventDipendente(idEvento).then(
            async (planningDetails: IPlanningDetails | null) => {
                if (planningDetails) {
                    this.setState({
                        showModal: true,
                        planningDetails,
                    });
                } else {
                    Swal.fire('Siamo spiacenti, l\'evento selezionato per la visualizzazione non è stato trovato.')
                }
            },
        );

        EventBus.dispatch("hideLoader");
    };

    closeModal() {
        this.setState({ showModal: false });
    }

    renderModalContent() {
        const { planningDetails } = this.state;

        return planningDetails ? <>
            <hr />
            <div id="planning_details_joborder">
                <strong>Commessa:</strong>{' '}
                {planningDetails.joborder.name} ({planningDetails.joborder.code})
            </div>
            <br />
            <div id="planning_details_customer">
                <strong>Cliente:</strong>{' '}
                {planningDetails.customer.ragione_sociale}
            </div>
            <br />
            <div id="planning_details_activities">
                <strong>Attività:</strong>{' '}
                {planningDetails.activities.length ? planningDetails.activities.map(
                    (activity: IPlanningActivity, index: number) => {
                        return <React.Fragment key={index}>
                            {activity.descrizione}
                            {index < planningDetails.activities.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_start">
                <strong>Data inizio:</strong>{' '}
                {moment(planningDetails.start).format('DD/MM/YYYY HH:mm')}
            </div>
            <br />
            <div id="planning_details_end">
                <strong>Data fine:</strong>{' '}
                {moment(planningDetails.end).format('DD/MM/YYYY HH:mm')}
            </div>
            <br />
            <div id="planning_details_people">
                <strong>Persone:</strong>{' '}
                {planningDetails.persons.length ? planningDetails.persons.map(
                    (person: IPlanningPeople, index: number) => {
                        return <React.Fragment key={index}>
                            {person.fullname}
                            {index < planningDetails.persons.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_devices">
                <strong>Dispositivi:</strong>{' '}
                {planningDetails.devices.length ? planningDetails.devices.map(
                    (device: IPlanningDevice, index: number) => {
                        return <React.Fragment key={index}>
                            {device.marca_modello} ({device.serial_number})
                            {index < planningDetails.devices.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_vehicles">
                <strong>Veicoli:</strong>{' '}
                {planningDetails.vehicles.length ? planningDetails.vehicles.map(
                    (vehicle: IPlanningVehicle, index: number) => {
                        return <React.Fragment key={index}>
                            {vehicle.brand} {vehicle.model} ({vehicle.plate})
                            {index < planningDetails.vehicles.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_licenses">
                <strong>Licenze:</strong>{' '}
                {planningDetails.licenses.length ? planningDetails.licenses.map(
                    (license: IPLanningLicense, index: number) => {
                        return <React.Fragment key={index}>
                            {license.prodotto.nome} ({license.identificativo})
                            {index < planningDetails.licenses.length - 1 ? ' • ' : '' }
                        </React.Fragment>
                    }
                ) : <>-</>}
            </div>
            <br />
            <div id="planning_details_status">
                <strong>Stato:</strong>{' '}
                {planningDetails.stato.nome}
                {planningDetails.stato.nome === 'Approved' ? <a
                    className='btn btn-primary px-2 py-1 ms-3'
                    onClick={async(e) => {
                        Swal.fire({
                            title: 'Sei sicuro di voler segnare questo evento come completato?',
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonColor: '#33dd33',
                            cancelButtonColor: '#dd3333',
                            confirmButtonText: 'Continua',
                            cancelButtonText: 'Annulla'
                        }).then(async (result: SweetAlertResult<any>) => {
                            if (result.isConfirmed) {
                                await planningService.setCompleteEventDipendente(planningDetails.id).then((value) => {
                                    Swal.fire("Lo stato dell'evento è stato aggiornato con successo", '', 'success').then(() => {
                                        window.location.reload();
                                    });
                                }).catch((e) => {
                                    Swal.fire("Si è verificato un errore durante l'aggiornamento dello stato dell'evento", '', 'error');
                                });
                            }
                        });
                    }}
                >
                    Completato
                </a> : <></>}
            </div>
            <br />
            <div id="planning_details_priority">
                <strong>Priorità:</strong>{' '}
                {planningDetails.priority}
            </div>
            <br />
            <div id="planning_details_address">
                <strong>Indirizzo:</strong>{' '}
                {planningDetails.address}
            </div>
            <br />
            <div id="planning_details_note">
                <strong>Note:</strong>{' '}
                {planningDetails.note}
            </div>
            <hr />
        </> : <h2>Evento non trovato.</h2>
    }

    closeModalForm() {
        this.setState({
            showModalForm: false,
            startDate: null,
            endDate: null
        });
    }
  
    handleSuccess() {
        window.location.hash = "#planning";
        window.location.reload();
    }

    async getMonth(
        year: number,
        month: number
    ) : Promise<IPlanningMonth<any>> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        const startDate = moment(`${year}-${fixDate((month + 1))}-01 00:00:00`);
        const daysInMonth = startDate.daysInMonth();
        const endDate = moment(`${year}-${fixDate((month + 1))}-${fixDate(daysInMonth)} 23:59:59`);
        const planningDates: IPlanningEventData[] | null = await PlanningService.getPeopleBetweenTwoDates(
            planningService.getBetweenTwoDatesDipendente,
            startDate,
            endDate
        );

        let planningColumns: TableBSColumn<any>[][] = [
            getGiorniSettimana().map((giorni: string, index: number) => {
                return {
                    id: `d_${index}`,
                    name: getGiornoSettimanaAbbreviato(
                        year,
                        month + 1,
                        (index + 1)
                    ),
                    data: "plannings",
                    render: (data: null, index: number, row: IPlanningMonthDipendente[]) => {
                        return row[index]['day'] <= daysInMonth ? <>
                            <div className='week-day'>{row[index]['day']}
                                <span className='week-day-name'>{getGiornoSettimana(
                                    year,
                                    month + 1,
                                    (index + 1)
                                )}</span>
                            </div> 
                            <br />
                            {row[index]['plannings'] && row[index]['plannings'].length
                                ? row[index]['plannings'].map((events: IPlanningEventData['plannings'], index2: number) => {
                                    return <React.Fragment key={index2}>
                                        {events && events.length
                                            ? events.map((event: any, index3: number) => {
                                                let startEvent: string | undefined;
                                                let endEvent: string | undefined;
                                                let description: string | undefined;
                                                let color: string | undefined;
                        
                                                if (event.type === 'P') {
                                                    startEvent = event.start;
                                                    endEvent = event.end;
                                                    description = `${event.joborder.name} (${event.customer.ragione_sociale})`;
                                                    color = event.stato.color;
                                                } else if (event.type === 'U') {
                                                    startEvent = event.inizio;
                                                    endEvent = event.fine;
                                                    description = `${event.causale} (${event.stato})`
                                                    color = event.colore
                                                }

                                                if (!startEvent || !endEvent || !description || !color) {
                                                    return null;
                                                }
                        
                                                const start = moment(startEvent);
                                                const end = moment(endEvent);

                                                return event
                                                    ? <div key={index3} className='event d-block p-1 pl-2 pr-2 mb-1 rounded small' style={{background: color}}>
                                                        <div>
                                                            {event.type === 'P' ? <a className='view_btn' data-id={`${event.id}`} title='Visualizza evento'>
                                                                <i className='fa fa-eye' /> Visualizza
                                                            </a> : <></>}
                                                        </div>
                                                        {start.format('HH:mm')}-{end.format('HH:mm')}: {description}
                                                    </div>
                                                : <></>
                                            })
                                            : <></>
                                        }
                                    </React.Fragment>
                                })
                            : <></>}
                        </> : <></>
                    },
                }
            })
        ];

        let planningData: IPlanningMonthDipendente[][] = [];
        const weeksInMonth = daysInMonth === 28 ? 4 : 5;

        let dayCount = 0;
        for (let w = 1; w <= weeksInMonth; w++) {
            planningData[w] = [];
            for(let i = 1; i <= 7; i++) {
                dayCount++;

                // eslint-disable-next-line no-loop-func
                const planningEvents = planningDates?.length ? planningDates?.map((data: IPlanningEventData) => {
                    // eslint-disable-next-line array-callback-return
                    const plannings: IPlanningEventData['plannings'] = data.plannings.length ? data.plannings.filter((value: any) => {
                        const date = moment(`${year}-${fixDate((month + 1))}-${fixDate(dayCount)}`)

                        let startEvent: string | undefined;
                        let endEvent: string | undefined;
                        
                        if (value.type === 'P') {
                            startEvent = value.start;
                            endEvent = value.end;
                        } else if (value.type === 'U') {
                            startEvent = value.inizio;
                            endEvent = value.fine;
                        }
                        
                        const start = moment(startEvent);
                        const end = moment(endEvent);

                        if (date.isBetween(start, end, undefined, '[]') ||
                            start.date() === dayCount ||
                            end.date() === dayCount
                        ) {
                            return value;
                        };
                    }) : []

                    return plannings;
                }) : [];

                planningData[w].push(
                    {
                        day: dayCount,
                        plannings: planningEvents
                    }
                );
            }
        }

        EventBus.dispatch("hideLoader");

        return {
            planningColumns: planningColumns,
            planningData
        }
    }

    async getInterval(
        selectedStartDate: string,
        selectedEndDate: string,
        type: IPlanningMonthType
    ) : Promise<any> {
        return null
    }

    render() {
        const { showModal, showModalForm, modalFormTitle, modalFormType, formFields, formInitialValues } = this.state;

        return  <React.Fragment>
            <Modal
                showModal={showModal}
                title={`Dettagli dell'evento`}
                type={'details'}
                closeCallback={this.closeModal.bind(this)}
                content={this.renderModalContent()}
            />
            <ModalForm
                id={`modal-form-${modalFormType}`}
                showModal={showModalForm}
                title={modalFormTitle}
                modalType={modalFormType}
                formFields={formFields}
                initialValues={formInitialValues}
                apiSubmit={this.apiSubmit}
                closeCallback={this.closeModalForm.bind(this)}
                successAction={this.handleSuccess.bind(this)}
            />
            <div id="planning-dipendente" className='custom-container'>
                <div className="card">
                    <div className="card-body">
                        <PlanningMonth
                            getMonth={this.getMonth.bind(this)}
                            getInterval={this.getInterval.bind(this)}
                            viewHandler={this.viewHandler.bind(this)}
                            type='dipendente'
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}