import React, { Component } from 'react';
import Swal from "sweetalert2";
import UserService from "../../services/api/user.service";
import { RitData, RitDocument } from "../../helpers/interfaces/user";
import { Formik, Form } from 'formik';
import eventBus from '../../common/EventBus';
import { generatePath } from "react-router-dom";
// import DomPurify from 'dompurify';

type Props = {
  history: {
    push(url: string): void;
  }
  match: {
    params: {
      id: string
    };
  }
};

type State = {
  ritHTML: string,
  formInitialValues: any,
  loading: boolean,
  disabledForm: boolean,
  luogo_intervento: string,
  descrizione_intervento: string,
  tipologia_intervento: string,
  t_minima: string,
  t_minima_text: string,
  t_massima: string,
  t_massima_text: string,
  vibrazioni: string,
  report_non_consegnati: string,
  report_consegnati: string,
  impaginazione: string,
  valori_anomali: string,
  misure_esaustive: string,
  no_misure: string,
  ulteriori_lavorazioni: string,
  sno_influenza: string,
  si_influenza: string,
  durata_intervento: string,
  strumentazione_inadeguata: string,
  scarsa_dimestichezza: string,
  non_completato: string,
  altro: string,
  altro_text: string,
  modifiche_cliente: string
}

export default class RitDipendente extends Component<Props, State> {

    constructor(props: Props) {
      super(props);

      this.state = {
        ritHTML: "",
        formInitialValues: {},
        loading: false,
        disabledForm: false, // mettere a true
        luogo_intervento: "",
        descrizione_intervento: "",
        tipologia_intervento: "",
        t_minima: "",
        t_minima_text: "",
        t_massima: "",
        t_massima_text: "",
        vibrazioni: "",
        report_non_consegnati: "",
        report_consegnati: "",
        impaginazione: "",
        valori_anomali: "",
        misure_esaustive: "",
        no_misure: "",
        ulteriori_lavorazioni: "",
        sno_influenza: "",
        si_influenza: "",
        durata_intervento: "",
        strumentazione_inadeguata: "",
        scarsa_dimestichezza: "",
        non_completato: "",
        altro: "",
        altro_text: "",
        modifiche_cliente: ""
      }
      
      this.handleCreateRit = this.handleCreateRit.bind(this);
      // this.handleChange = this.handleChange(this);
    }

    async componentDidMount() {
      eventBus.dispatch("showLoader", { text: 'Caricamento del RIT in corso...' });
      
      await UserService.getRitDocument(Number(this.props.match.params.id))
        .then((response: RitData) => {
          eventBus.dispatch("hideLoader");

          if (response && response.html) {
            const encodedHTML = response.html;
            const decodedHTML = atob(encodedHTML);

            this.setState({ ritHTML: decodedHTML, formInitialValues: {} });
          } else {
            Swal.fire({
                title: 'Errore',
                text: 'Si è verificato un errore nel caricamento del rapportino.',
                icon: 'error',
            })
          }
        })
        .catch((error: any) => {
            eventBus.dispatch("hideLoader");

            const errorMsg = error?.response?.data?.error.includes('missing') ? 'Non è stato trovato alcun RIT relativo alla commessa.' : 'Si è verificato un errore.'
            Swal.fire({
                title: 'Errore',
                text: errorMsg,
                icon: 'error',
            }).then(async () => {
                const url = generatePath("/dipendente/rit-dipendente");
                let link = document.createElement('a');
                link.href = url;
                link.click();
            });
        });
    }
    
    handleChange(argument: string): any {
        const inputValue = (document.getElementById(argument) as HTMLInputElement).value
        
        switch (argument) {
            case 'luogo_intervento':
                this.setState({ luogo_intervento: inputValue });
                return
            case 'descrizione_intervento':
                this.setState({ descrizione_intervento: inputValue });
                return
            case 'tipologia_intervento':
                this.setState({ tipologia_intervento: inputValue });
                return
            case 't_minima':
                if ( (document.getElementById('t_minima') as HTMLInputElement).checked )
                    this.setState({ t_minima: inputValue });
                return
            case 't_minima_text':
                this.setState({ t_minima_text: inputValue });
                return
            case 't_massima':
                if ( (document.getElementById('t_massima') as HTMLInputElement).checked )
                    this.setState({ t_massima: inputValue });
                return
            case 't_massima_text':
                this.setState({ t_massima_text: inputValue });
                return
            case 'vibrazioni':
                if ( (document.getElementById('vibrazioni') as HTMLInputElement).checked )
                    this.setState({ vibrazioni: inputValue });
                return
            case 'report_non_consegnati':
                if ( (document.getElementById('report_non_consegnati') as HTMLInputElement).checked )
                    this.setState({ report_non_consegnati: inputValue });
                return
            case 'report_consegnati':
                if ( (document.getElementById('report_consegnati') as HTMLInputElement).checked )
                    this.setState({ report_consegnati: inputValue });
                return
            case 'impaginazione':
                if ( (document.getElementById('impaginazione') as HTMLInputElement).checked )
                    this.setState({ impaginazione: inputValue });
                return 
            case 'valori_anomali':
                if ( (document.getElementById('valori_anomali') as HTMLInputElement).checked )
                    this.setState({ valori_anomali: inputValue });
                return
            case 'misure_esaustive':
                if ( (document.getElementById('misure_esaustive') as HTMLInputElement).checked )
                    this.setState({ misure_esaustive: inputValue });
                return
            case 'no_misure':
                if ( (document.getElementById('no_misure') as HTMLInputElement).checked )
                    this.setState({ no_misure: inputValue });
                return
            case 'ulteriori_lavorazioni':
                if ( (document.getElementById('ulteriori_lavorazioni') as HTMLInputElement).checked )
                    this.setState({ ulteriori_lavorazioni: inputValue });
                return
            case 'sno_influenza':
                if ( (document.getElementById('sno_influenza') as HTMLInputElement).checked )
                    this.setState({ sno_influenza: inputValue });
                return
            case 'si_influenza':
                if ( (document.getElementById('si_influenza') as HTMLInputElement).checked )
                    this.setState({ si_influenza: inputValue });
                return
            case 'durata_intervento':
                if ( (document.getElementById('durata_intervento') as HTMLInputElement).checked )
                    this.setState({ durata_intervento: inputValue });
                return
            case 'strumentazione_inadeguata':
                if ( (document.getElementById('strumentazione_inadeguata') as HTMLInputElement).checked )
                    this.setState({ strumentazione_inadeguata: inputValue });
                return
            case 'scarsa_dimestichezza':
                if ( (document.getElementById('scarsa_dimestichezza') as HTMLInputElement).checked )
                    this.setState({ scarsa_dimestichezza: inputValue });
                return
            case 'non_completato':
                if ( (document.getElementById('non_completato') as HTMLInputElement).checked )
                    this.setState({ non_completato: inputValue });
                return
            case 'altro':
                if ( (document.getElementById('altro') as HTMLInputElement).checked )
                    this.setState({ altro: inputValue });
                return
            case 'altro_text':
                this.setState({ altro_text: inputValue });
                return
            case 'modifiche_cliente':
                if ( (document.getElementById('modifiche_cliente') as HTMLInputElement).checked )
                    this.setState({ modifiche_cliente: inputValue });
                return
        }
    }

    async handleCreateRit() {
        eventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        document.getElementById("luogo_intervento")?.addEventListener("change", this.handleChange("luogo_intervento"));
        document.getElementById("descrizione_intervento")?.addEventListener("change", this.handleChange("descrizione_intervento"));
        document.getElementById("tipologia_intervento")?.addEventListener("change", this.handleChange("tipologia_intervento"));
        document.getElementById("t_minima")?.addEventListener("change", this.handleChange("t_minima"));
        document.getElementById("t_minima_text")?.addEventListener("change", this.handleChange("t_minima_text"));
        document.getElementById("t_massima")?.addEventListener("change", this.handleChange("t_massima"));
        document.getElementById("t_massima_text")?.addEventListener("change", this.handleChange("t_massima_text"));
        document.getElementById("vibrazioni")?.addEventListener("change", this.handleChange("vibrazioni"));
        document.getElementById("report_non_consegnati")?.addEventListener("change", this.handleChange("report_non_consegnati"));
        document.getElementById("report_consegnati")?.addEventListener("change", this.handleChange("report_consegnati"));
        document.getElementById("impaginazione")?.addEventListener("change", this.handleChange("impaginazione"));
        document.getElementById("valori_anomali")?.addEventListener("change", this.handleChange("valori_anomali"));
        document.getElementById("misure_esaustive")?.addEventListener("change", this.handleChange("misure_esaustive"));
        document.getElementById("no_misure")?.addEventListener("change", this.handleChange("no_misure"));
        document.getElementById("ulteriori_lavorazioni")?.addEventListener("change", this.handleChange("ulteriori_lavorazioni"));
        document.getElementById("sno_influenza")?.addEventListener("change", this.handleChange("sno_influenza"));
        document.getElementById("si_influenza")?.addEventListener("change", this.handleChange("si_influenza"));
        document.getElementById("durata_intervento")?.addEventListener("change", this.handleChange("durata_intervento"));
        document.getElementById("strumentazione_inadeguata")?.addEventListener("change", this.handleChange("strumentazione_inadeguata"));
        document.getElementById("scarsa_dimestichezza")?.addEventListener("change", this.handleChange("scarsa_dimestichezza"));
        document.getElementById("non_completato")?.addEventListener("change", this.handleChange("non_completato"));
        document.getElementById("altro")?.addEventListener("change", this.handleChange("altro"));
        document.getElementById("altro_text")?.addEventListener("change", this.handleChange("altro_text"));
        document.getElementById("modifiche_cliente")?.addEventListener("change", this.handleChange("modifiche_cliente"));

        let ritBody: RitDocument = {
            fields: {                
                luogo_intervento: this.state.luogo_intervento,
                descrizione_intervento: this.state.descrizione_intervento,
                tipologia_intervento: this.state.tipologia_intervento,
                t_minima: this.state.t_minima,
                t_minima_text: this.state.t_minima_text,
                t_massima: this.state.t_massima,
                t_massima_text: this.state.t_massima_text,
                vibrazioni: this.state.vibrazioni,
                // report_non_consegnati: this.state.report_non_consegnati,
                // report_consegnati: this.state.report_consegnati,
                report_collaudo: this.state.report_consegnati ? this.state.report_consegnati : this.state.report_non_consegnati ? this.state.report_non_consegnati : "",
                impaginazione: this.state.impaginazione,
                valori_anomali: this.state.valori_anomali,
                // misure_esaustive: this.state.misure_esaustive,
                // no_misure: this.state.no_misure,
                // ulteriori_lavorazioni: this.state.ulteriori_lavorazioni,
                misure: this.state.misure_esaustive ? this.state.misure_esaustive :
                        this.state.no_misure ? this.state.no_misure :
                        this.state.ulteriori_lavorazioni ? this.state.ulteriori_lavorazioni : "",
                // sno_influenza: this.state.sno_influenza,
                // si_influenza: this.state.si_influenza,
                influenza: this.state.si_influenza ? this.state.si_influenza : this.state.sno_influenza ? this.state.sno_influenza : "",
                durata_intervento: this.state.durata_intervento,
                strumentazione_inadeguata: this.state.strumentazione_inadeguata,
                scarsa_dimestichezza: this.state.scarsa_dimestichezza,
                non_completato: this.state.non_completato,
                altro: this.state.altro,
                altro_text: this.state.altro_text,
                modifiche_cliente: this.state.modifiche_cliente
            }
        }

        await UserService.putRitData(Number(this.props.match.params.id), ritBody)
        .then(
            response => {
                eventBus.dispatch("hideLoader");
                if (!response || response.error) {
                    Swal.fire(
                        'Salvataggio non effettuato.',
                        response.error ?? "",
                        'error'
                    ).then(
                        () => this.setState({
                            loading: false
                        })
                    );
                } else {
                    Swal.fire(
                        'Operazione eseguita.',
                        '',
                        'success'
                    ).then(
                        () => this.setState(
                            { loading: false },
                            // () => this.props.history.push("/dipendente/home")
                        )
                    );
                }
            },
            error => {
                eventBus.dispatch("hideLoader");
                Swal.fire(
                    'Si è verificato un errore.',
                    error,
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    render() {
        const { ritHTML, formInitialValues, loading, disabledForm, } = this.state;

        return (
            <div className="custom-container">

                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        onSubmit={this.handleCreateRit}
                    >
                    {({ setFieldValue }) => (
                        <Form className="card">
                            <div className="card-body">
                                {/* <div dangerouslySetInnerHTML={{ __html: DomPurify.sanitize(ritHTML) }} /> */}
                                <div dangerouslySetInnerHTML={{ __html: ritHTML }} />

                                <div className="col-12 d-flex justify-content-end mt-3">
                                    <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                                        {
                                            loading && <span className="spinner-border spinner-border-sm mr-1"></span>
                                        }
                                        <span>Salva e invia RIT</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                    </Formik>
                </React.Fragment>
            </div>
        );
    }
}