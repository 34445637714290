import Swal from 'sweetalert2';
import {
    IPlanning,
    IPlanningEvent,
    iPlanningUnavailableObject,
    IPlanningEventData,
    IPlanningIssuer,
    IPlanningDetails,
    IPlanningIVL,
    IPlanningIVLInstrument,
    IPlanningIVLVehicle,
    IPlanningIVLLicense
} from '../helpers/interfaces/planning';
import planningService from './api/planning.service';
import ObjectToArray from '../helpers/ObjectToArray';
import authService from './auth.service';
import moment from "moment";
import 'moment/locale/it';
import { IPlanningMonthType } from '../pages/planning/planning';

class PlanningService {
    async getPeopleBetweenTwoDates(
        apiService: (startDate: string, endDate: string) => Promise<IPlanning>,
        startDate: moment.Moment,
        endDate: moment.Moment
    ) : Promise<IPlanningEventData[] | null> {
        return await apiService(
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD')
        ).then(
            async (data: IPlanning | null) => {
                return data ? await this.createPeopleData(data) : null;
            },
            (error: any) => {
                Promise.reject(error);
                return null;
            }
        ).catch((error: any) => {
            Swal.fire({
                title: 'Errore imprevisto',
                text: 'A causa di un errore imprevisto non è stato possibile caricare i dati.',
                icon: 'error',
            });

            return null;
        });
    }

    async getIVLBetweenTwoDates(
        startDate: moment.Moment,
        endDate: moment.Moment,
        type: IPlanningMonthType
    ) : Promise<IPlanningEventData[] | null> {
        return await planningService.getIVLDates(
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD')
        ).then(
            async (data: IPlanningIVL) => {
                let plannings: IPlanningEventData[] = [];

                if (type === 'instruments') {
                    data.instruments.length && data.instruments.forEach((result: IPlanningIVLInstrument) => {
                        if (!plannings[result.instrument.id]) {
                            plannings[result.instrument.id] = {
                                resource: {
                                    ...result.instrument,
                                    fullname: `${result.instrument.marca_modello} (${result.instrument.serial_number})`
                                },
                                plannings: []
                            }
                        }

                        result.plannings.length && result.plannings.forEach((planningEvent: IPlanningDetails) => {
                            plannings[result.instrument.id].plannings.push({...planningEvent, type: 'I'});
                        });
                    });
                }

                if (type === 'vehicles') {
                    data.vehicles.length && data.vehicles.forEach((result: IPlanningIVLVehicle) => {
                        if (!plannings[result.vehicle.id]) {
                            plannings[result.vehicle.id] = {
                                resource: {
                                    ...result.vehicle,
                                    fullname: `${result.vehicle.brand} ${result.vehicle.model} (${result.vehicle.plate})`
                                },
                                plannings: []
                            }
                        }

                        result.plannings.length && result.plannings.forEach((planningEvent: IPlanningDetails) => {
                            plannings[result.vehicle.id].plannings.push({...planningEvent, type: 'V'});
                        });
                    });
                }

                if (type === 'licenses') {
                    data.licenses.length && data.licenses.forEach((result: IPlanningIVLLicense) => {
                        if (!plannings[result.license.id]) {
                            plannings[result.license.id] = {
                                resource: {
                                    ...result.license,
                                    fullname: `${result.license.prodotto.produttore} ${result.license.prodotto.nome} (${result.license.identificativo})`
                                },
                                plannings: []
                            }
                        }

                        result.plannings.length && result.plannings.forEach((planningEvent: IPlanningDetails) => {
                            plannings[result.license.id].plannings.push({...planningEvent, type: 'L'});
                        });
                    });
                }

                return plannings;
            },
            (error: any) => {
                Promise.reject(error);
                return null;
            }
        ).catch((error: any) => {
            Swal.fire({
                title: 'Errore imprevisto',
                text: 'A causa di un errore imprevisto non è stato possibile caricare i dati.',
                icon: 'error',
            });

            return null;
        });
    }

    async getDayDipendente(
        startDate: moment.Moment,
    ) : Promise<IPlanningEventData[] | null> {
        const currentUser = await authService.getCurrentUser();
        if (!currentUser) { return null; }

        return await planningService.getDayDipendente(
            startDate.format('YYYY-MM-DD')
        ).then(
            async (result: IPlanning | null) => {
                let data: IPlanning | null = result;
                if (data && !data.events[0]) {
                    data.events.push({
                        resource: {
                            id: currentUser.id,
                            fullname: currentUser.name + '' + currentUser.lastname
                        },
                        plannings: []
                    })
                }

                return data ? await this.createPeopleData(data) : null;
            },
        ).catch((error: any) => {
            Swal.fire({
                title: 'Errore imprevisto',
                text: 'A causa di un errore imprevisto non è stato possibile caricare le informazioni.',
                icon: 'error',
            });

            return null;
        });
    }

    async createPeopleData(
        data: IPlanning
    ) : Promise<IPlanningEventData[]> {
        let plannings: IPlanningEventData[] = [];
        data.events.forEach((event: IPlanningEvent & { issuer?: IPlanningIssuer }, index: number) => {
            if (!plannings[event.resource.id]) {
                plannings[event.resource.id] = {
                    resource: event.resource,
                    plannings: []
                }
            }

            event.plannings.length && event.plannings.forEach((planningEvent: IPlanningDetails) => {
                plannings[event.resource.id].plannings.push({...planningEvent, type: 'P'});
            })
        });

        const unavailableData = ObjectToArray<iPlanningUnavailableObject[][]>(data.unavailable);

        unavailableData.forEach((unavailables: iPlanningUnavailableObject[]) => {
            unavailables.forEach((unavailable) => {
                if (!plannings[unavailable.resource.id]) {
                    plannings[unavailable.resource.id] = {
                        resource: unavailable.resource,
                        plannings: []
                    }
                }

                plannings[unavailable.resource.id].plannings.push({...unavailable, type: 'U'})
            })
        });

        return plannings;
    }
}

export default new PlanningService()